import React from 'react';
import Contact from '../../components/sections/contact/Contact';

const ContactScreen = () => {

    return (
        <div className='contact-styles'>
            <Contact />
        </div>
    )
}
export default ContactScreen;