import React from "react";
export default function PrivacyPolicy() {
  return (
    <div className="container">
      <div className="text-center py-5">
        <h2>
          <strong>Privacy policy</strong>
        </h2>

        <div className="col-3"></div>
        <div className="col-12 mx-auto text-start">
          <p>
            <strong>INTRODUCTION & SCOPE</strong>
          </p>
          <div>
            <p>
              {" "}
              Marvel Maids Cleaning Services (AGProCleaing, “we”, “our”, “us”)
              respects your privacy and we will manage your personal information
              in accordance the B.C. Personal Information Protection Act and
              other applicable provincial or federal legislation. This Privacy
              Policy (the “Policy”) describes how Marvel Maids Cleaning collects,
              uses, discloses, and protects personal information about our
              customers and users of the Marvel Maids Cleaning website,
              https://www.agprocleaningservices.com Marvel Maids Cleaning collects
              personal information about customers and users of the Sites: (a)
              through the Sites; (b) in e-mails and other electronic messages
              between a customer or user and the Sites; (c) through purchases of
              Marvel Maids Cleaning products made on the Products Site; (d) through
              booking Marvel Maids Cleaning cleaning services made on the Cleaning
              Site; (e) through telephone calls to our call center handled by AG
              Pro Cleaning Headquarter employees and (f) in providing products
              and services made available through the Sites (the “Services”).
              This Policy applies to all users of the Sites and the Services
              (“Users”).
            </p>
          </div>
          <div>
            <h6>
              <strong className="py-5">GETTING YOUR CONSENT</strong>
            </h6>
            <p>
              Please read this Policy carefully. By visiting and using our
              Sites, creating an Marvel Maids Cleaning Account (defined below),
              purchasing any products through the Sites, or using the Services,
              you acknowledge the notices in this Policy, and you consent to our
              collection, use, disclosure, and retention of your personal
              information in accordance with this Policy and as otherwise
              permitted by law. You may withdraw your consent at any time by
              giving Marvel Maids Cleaning reasonable notice, but consent may not be
              withdrawn where doing so would frustrate performance of a legal
              obligation.
            </p>
          </div>
          <br />
          <div>
            <h6>
              <strong>COLLECTION OF PERSONAL INFORMATION</strong>
            </h6>
            <p>
              Marvel Maids Cleaning collects personal information provided by Users
              when they interact with us or use the Sites and/or the Services.
              Marvel Maids Cleaning also collects some personal information from Users
              automatically, which is addressed in more detail below.
            </p>
          </div>
          <br />
          <div>
            <h6>
              <strong>
                1. Personal Information You Provide to Marvel Maids Cleaning
              </strong>
            </h6>
            <br />
            <p>
              Contact information: we collect your personal information when you
              contact Marvel Maids Cleaning directly to inquire about the Sites and/or
              the Services. This may include your name, e-mail address, phone
              number, mailing address and other information that you choose to
              provide to us. For example, on the Sites, we collect your name and
              e-mail address when you complete the “Get in touch with us” form
              on the Sites, or when you send us a message or inquiry via e-mail.
              Users may be given an option on the Sites to sign-up to receive
              electronic communication from Marvel Maids Cleaning regarding our
              Services, news and events, including our newsletter. In order to
              receive this electronic communication, we need to collect your
              e-mail address. Live chat: If you choose to use the live chat
              feature on the Sites, you can message and live chat with Marvel Maids
              Cleaning through our Sites. The chats are monitored and recorded
              with the assistance of our service provider. We collect any
              personal information that you choose to provide to Marvel Maids Cleaning
              through the chat feature on the Sites. Account and profile
              information: we collect your personal information when you
              register for an Marvel Maids Cleaning account (“Account”), and when you
              create or modify your Account or user profile. Users who want to
              book our home cleaning and maid services will first have to set up
              an Account on our Cleaning Site (“Cleaning Service Account”).
              Personal information that we collect from Users when setting up a
              Cleaning Service Account includes first and last name, username
              and password, e-mail address, phone number, mailing address,
              cleaning preferences, and information about your home such as
              number of Bedrooms, Bathrooms, Size of your home in Sq Ft, Levels,
              Floor details, set of up of things/ furnishings, Presence of
              Children, Pets and other information such as alarm codes,
              instructions for cleaning your home. This enables us to Customize
              the cleaning service and provide Costing estimates. Users who want
              to purchase our cleaning products will set up an account on our
              Products Site (“Cleaning Products Account”). Personal information
              that we collect from Users when setting up a Cleaning Products
              Account includes first and last name, username and password,
              e-mail address, phone number, and billing and shipping addresses.
              Gift certificate information: when you purchase an Marvel Maids Cleaning
              Premium Home Cleaning gift certificate, we collect your name and
              e-mail address, as well as the name and e-mail address of the gift
              recipient. We will e-mail the gift certificate to the recipient,
              and e-mail you a confirmation of the gift certificate. In the
              e-mail to the recipient, the recipient will be provided with your
              name. The recipient will have to create an Account in order to use
              the services paid for on the gift certificate Call centre
              information: when you call the Marvel Maids Cleaning call centre (e.g.
              to request customer support, to book a cleaning, or to request a
              quote), those telephone calls may be recorded and we may collect
              personal information that you provide during those calls, such as
              your name, telephone number, e-mail address, and payment
              information (e.g. credit or debit card number, expiry date,
              security code). Payment information: we collect a User’s billing
              information (such as credit or debit card numbers) when a User
              books a cleaning service with Marvel Maids over the telephone. We do not
              collect financial information directly from Users through the
              Sites. We use third party payment processors to handle payments
              for us via our Sites. The third party service providers will
              receive your billing information when you add it to your Account.
              Users have a choice to save their credit card information to their
              Account for easy repeat purchases, or when you book a cleaning
              service and/or purchase products through our Sites. Contest and
              promotion information: from time to time, Marvel Maids Cleaning and/or
              our vendor partners may run contests or promotions by way of
              specific contest entry forms or by coupons enclosed within
              products. Users are encouraged to read and understand the contest
              rules for each of these contests and promotions. By participating
              in a contest or promotion, you are agreeing to the rules that
              govern that specific contest or promotion. Marvel Maids Cleaning may
              also run contests or promotions which may be available online via
              our Sites, or through our social media accounts. All contests are
              subject to rules that will be available with each particular
              contest. If a User chooses to participate in the contest, the User
              will be asked for their name and contact information. Social media
              information: when you visit one of our social media sites (e.g.
              Facebook, Instagram, Twitter, Pinterest), we may collect personal
              information that you choose to submit to us such as your name,
              contact information, or any other information you choose to
              provide. Job application information: Marvel Maids Cleaning collects
              personal information from job applicants through the Sites and via
              e-mail to careers@Marvel Maids Cleaning.com. This includes: name,
              address, e-mail address, phone number, as well as any resume that
              a User chooses to submit with a job application through the
              Cleaning Site or via e-mail. Other information: we collect any
              other personal information that Users submit directly to Marvel Maids
              Cleaning on a voluntary basis.
            </p>
            <h6>
              <strong>2. Ratings, Reviews and Comments</strong>
            </h6>
            <p>
              We collect the personal information that you include in any
              feedback you provide to Marvel Maids Cleaning by e-mail, by telephone,
              through the Sites, as well as through our social media platforms.
              This includes ratings and reviews given by Users about the
              Services, as well as feedback that you provide to us about the
              Sites. Users must provide Marvel Maids Cleaning with their name and
              e-mail address to submit a product review on the Products Site. We
              also collect ratings and review information about the Services
              from third party websites and may publish that information on the
              review pages of our Sites and use them in our Social Media
              promotions. Our Reviews aggregators have their own privacy
              policies and we do not control these third-party websites. Users
              are encouraged to read the privacy policies of websites where they
              leave a review about Marvel Maids Cleaning.
            </p>
            <h6>
              <strong>
                3. Personal Information Automatically Collected When Using the
                Sites
              </strong>
            </h6>
            <p>
              Marvel Maids Cleaning collects device, technical and usage information
              about Users via the Sites. This includes: IP address, web browser
              type, mobile operating system, information about the sections of
              the Sites visited, the date and time of your use of the Sites,
              your actions within the Sites (e.g., placing products in a cart,
              purchases made, products searched for), crash and other system
              activity, certain content that you download from the Sites, and
              similar details. Marvel Maids Cleaning cleaners may also take
              photographs of customers’ homes when they are doing a cleaning to
              communicate with Customers about any damages spotted before
              cleaning or to enquire about cleaning instruction on a particular
              space (Eg. Broken countertops or Table etc)
            </p>
            <h6>
              <strong>USE OF YOUR PERSONAL INFORMATION</strong>
            </h6>
            <p>
              Marvel Maids Cleaning may use the personal information that it collects
              from Users for the following purposes: Contact information: we use
              your contact information to contact and correspond with you
              directly about our relationship, and your use of the Sites and/or
              Services. Marvel Maids Cleaning may contact you to inform you about it’s
              services in different ways including by mail, email, telephone, or
              other means to which you have agreed. We may use your contact
              information to provide you with information, services, or products
              that you have requested, to provide you information about cleaning
              services that you have booked, and to provide you with information
              about products that you have ordered through the Sites. Where we
              have your consent to do so, we may send you information about AG
              Pro Cleaning Services, including general updates and
              announcements, coupons, contest and event information, and
              newsletters (see below for information on how to “opt-out” of
              receiving certain communications from us).
            </p>
            <p>
              <strong>Live chat:</strong> we may use the personal information
              you provide to us in the live chat (if any) to respond to your
              questions or comments about the Services, or to book a cleaning
              service
            </p>
            <p>
              <strong>Account and profile information: </strong>we use your
              Account and profile information to set up provide you with access
              to certain areas of the Sites, to create and set up your Account
              and user profile, to administer your use of the Sites and/or
              Services, to book requested cleaning services, and to send you
              products that you have purchased.
            </p>
            <p>
              <strong>Gift certificate information: </strong>we use this
              information to process your request for an Marvel Maids Cleaning Premium
              Home Cleaning gift certificate, and to contact the intended gift
              recipient about the gift certificate.
            </p>
            <p>
              <strong>Call centre information:</strong> we use personal
              information that you provide when you call our call centre for the
              purposes of providing you with any information that you request
              (e.g. a quote) and to book cleaning services.
            </p>
            <p>
              <strong>Payment information:</strong>
              we use payment information to facilitate payments for our products
              and services. As explained above, we use a third-party payment
              processor to handle payments made via the Sites.
            </p>
            <p>
              <strong>Contest and promotion information:</strong>
              we use personal information that Users provide to us through
              contests to contact the User should the User win, unless the User
              opts-in to receiving other communication from Marvel Maids Cleaning.
            </p>
            <p>
              <strong>Job application information:</strong>
              we use personal information provided with job applications via
              e-mail and our Sites to assess applicants’ eligibility and
              suitability for employment with Marvel Maids Cleaning.
            </p>
            <p>
              <strong>Social media information:</strong>
              we use this information for the purpose of responding to inquiries
              that Users have made to us through social media, or to enter Users
              in contests that we are running on social media.
            </p>
            <p>
              <strong>Ratings and reviews:</strong>
              we use rating and review information to improve the Sites and/or
              Services, including to administer and resolve technical issues on
              the Sites. We may also use this information to investigate and
              address User concerns. In addition, we may use this information to
              suspend or deactivate the accounts of Users, and to assist us in
              training our employees. Reviews submitted by a User about our
              cleaning and maid services may be posted on our Cleaning Site and
              other Social Media platforms. Reviews submitted by a User about
              our cleaning products may be posted on our Products Site. If a
              User provides a rating or review about our Services, Marvel Maids
              Cleaning may post the User’s first name, the first initial of
              their last name, and your city and province, along with your
              rating or review on the Sites. User reviews may be accessible to
              all visitors to the Sites, so please do not include any personal
              information in the reviews and comments. These reviews may be
              shared with our employees for training and development purposes.
            </p>
            <p>
              <strong>Device, technical and usage information:</strong>
              we use device information to understand traffic and activity on
              the Sites, to enable us to improve the Sites and/or the Services,
              to understand what drives traffic to our Sites, to understand
              interest in our Services, and to tailor our marketing.
            </p>
            <p>
              Users (Customers) will not have access to contact the cleaning
              team directly but they can call our Call center for leaving any
              messages/ instructions. However, our cleaning team may call the
              customer to get guidance on directions to their home/access to
              enter their home/to discuss questions about the service.
            </p>
            <p>
              In general, Marvel Maids Cleaning may also use the personal information
              we collect:
            </p>
            <p>
              <li>
                to ensure compliance with and identify violations of the Terms
                of Use;
              </li>
            </p>
            <p>
              <li>
                to enforce our rights arising from any contracts between Marvel Maids
                Cleaning and a User.
              </li>
            </p>
            <p>
              <li> to enforce billing and collections.</li>
            </p>
            <p>
              <li>
                to identify and prevent fraudulent activity and to protect
                security of the Sites.
              </li>
            </p>
            <p>
              <li>to meet legal and regulatory requirements;</li>
            </p>
            <p>
              <li>
                to facilitate such other services and activities, as we may
                identify to you at the time.
              </li>
            </p>
            <p>
              Marvel Maids Cleaning will only use your personal information for the
              purpose for which it was originally collected, or for a use
              consistent with that purpose, unless you expressly consent
              otherwise, or it is permitted or required by law
            </p>
            <p>
              <h6>
                <strong>COOKIES AND TRACKING TECHNOLOGY</strong>
              </h6>
            </p>
            <p>
              Marvel Maids Cleaning uses cookies and other tracking technologies on
              the Sites to collect information as you navigate through the
              Sites. This includes the device, technical and usage information
              described in the sections above. Marvel Maids Cleaning may also collect
              information through social media platforms (e.g. when a User posts
              or views information that mentions Marvel Maids Cleaning, our products,
              services, promotions or when a User interacts with or likes
              content on our social media platforms. Please note that
              interactions with us on social media platforms are governed by the
              privacy policies of those platforms.
            </p>
            <p>
              Cookies and other tracking technologies allow us to track and
              understand the activity of Users on the Sites and to modify the
              Sites to reflect a User’s preferences. Cookies also enable our
              Sites to save and retrieve information that you enter (for
              example, user preferences and settings). Cookies may remain on
              your device after you leave the Sites (until the cookie expires or
              is deleted by you).
            </p>
            <p>
              Marvel Maids Cleaning uses third-party analytics tools to track certain
              activities on our Sites, and to learn more about how our customers
              and Users interact with our Sites so we can improve existing
              products and services, develop new products, services, promotions,
              contests, and better understand how to communicate with you.
              Google Analytics uses cookies to track certain usage information
              on the Sites and to create reports for Marvel Maids Cleaning about
              activities of Users. Such information may include aggregated
              information about the devices, networks, page visits, duration of
              visits and click through information of visitors to our Sites
            </p>
            <p>
              We may share personal information about users (like email
              addresses or postal code) with social networking platforms to
              create custom audiences for advertising. This is used only at the
              backend for customizing user experiences and will not be displayed
              anywhere. Marvel Maids Cleaning may use third-party tracking tools to
              display behaviour-based advertising/targeted ads? Behaviour-based
              advertising, also known as personalized or targeted ads, are
              displayed to website visitors based on information from activities
              such as purchasing on the site or visiting other websites that
              contain the business’ ads.
            </p>
            <h6>
              <strong>
                CHOICES YOU HAVE ABOUT HOW WE USE AND SHARE YOUR PERSONAL
                INFORMATION
              </strong>
            </h6>
            <p>
              Marvel Maids Cleaning wants you to understand your choices and make
              informed decisions about how we use and disclose your personal
              information. There are several options available for you to manage
              your privacy preferences including, for example by managing your
              preferences within your Account, contacting Marvel Maids Cleaning
              directly, changing your browser or device settings, and/or by
              contacting third parties.
            </p>
            <h6>
              <strong>
                1. Opting Out of Marketing Communications from Marvel Maids Cleaning
              </strong>
            </h6>
            <p>
              If you provide Marvel Maids Cleaning with your e-mail address and mobile
              telephone number and “opt-in” to receiving messages from us via
              the e-mail address and/or telephone number provided, you may
              receive electronic communications from us from time to time. These
              electronic communications will provide you with our contact
              information and a method to opt-out and unsubscribe from receiving
              marketing information and/or any further communications from us.
              You can also opt-out of receiving further communications from AG
              Pro Cleaning by contacting the Privacy Officer at the details
              below.
            </p>
            <p>
              Marvel Maids Cleaning may use your e-mail address to communicate with
              you regarding important matters, such as information about your
              Account, as well as services and products that you have ordered or
              purchased. You may not opt-out of receiving communications
              required by law, or necessary to provide you with requested
              services and/or products.
            </p>
            <h6>
              <strong>2. Cookies & Tracking Technologies</strong>
            </h6>
            <p>
              You can disable cookies by adjusting the settings on your browser.
              If you disable or refuse cookies, please note that some parts of
              the Sites may not be accessible to you or may not function
              properly. To learn more about Google’s privacy practices,
              including how you can view and edit your cookie preferences,
              please see the Google Privacy Policy.
            </p>
            <h6>
              <strong>DISCLOSURE OF YOUR PERSONAL INFORMATION</strong>
            </h6>
            <p>
              Except as described in this Policy or in other situations where we
              have provided you with prior notice, have obtained your consent,
              or are obligated or permitted by law, Marvel Maids Cleaning will not
              share your personal information with third parties.
            </p>
            <p>
              <li>
                <strong>Website analytics</strong> as described above, we use
                Google Analytics to track and analyse usage information about
                our Sites.
              </li>
            </p>
            <p>
              <li>
                <strong>Electronic communications:</strong> we use a third-party
                email service provider, Klaviyo Inc., and Yotpo to send
                e-mails/texts to our customers and others who have opted-in to
                receiving marketing or promotional electronic communication from
                Marvel Maids Cleaning. This includes Marvel Maids Cleaning newsletters.
                Klaviyo’s privacy policy. Yotpo’s privacy policy.
              </li>
            </p>
            <p>
              <li>
                <strong>E-commerce platform:</strong> we use Shopify Inc.’s
                e-commerce platform to sell our cleaning products on the
                Products Site. Shopify’s privacy policy is available here. We
                use HubSpot to host our cleaning services website site.
                Shopify’s privacy policy is available
              </li>
            </p>
            <p>
              <li>
                <strong>Payment processing: </strong> We use third party payment
                processing platforms to facilitate payment transactions made on
                the Sites. These include: Stripe, Inc. and Shopify.
              </li>
            </p>
            <p>
              <li>
                <strong>Customer management:</strong>we use Salesforce.com,
                Inc., to provide us with customer relationship management
                services.
              </li>
            </p>
            <p>
              <li>
                We use Yotpo to aggregate user reviews and publish them on third
                party websites.
              </li>
            </p>
            <p>
              Marvel Maids Cleaning third-party service providers will have access to
              the personal information needed to perform their functions, but
              are only provided with the limited amount of information required
              to perform their services. When Marvel Maids Cleaning uses third-party
              service providers, it requires the service providers to protect
              your personal information in accordance with the law and with
              appropriate safeguards for the protection of the personal
              information. Any such sharing of your personal information by AG
              Pro Cleaning to a third party service provider will be conditional
              upon the information being used solely for the purpose for which
              it has been shared.
            </p>
            <p>
              <strong>Contests and Promotions</strong>
            </p>
            <p>
              As described above, from time to time, Marvel Maids Cleaning and/or our
              vendor partners may run contests or promotions. If a User chooses
              to enter a contest or promotion run by Marvel Maids Cleaning and/or our
              vendor partners, the User’s personal information may be disclosed
              to third parties or the public in connection with the
              administration of the contest or promotion, including, in
              connection with winner selection, prize fulfillment, and as
              permitted by the contest or promotion’s rules.
            </p>
            <p>
              <strong>Sale or Transfer of the Business</strong>
            </p>
            <p>
              Marvel Maids Cleaning may decide to sell or transfer all or part of our
              business to a related company or to a third party, to merge with
              another entity, to insure or securitize our assets, or to engage
              in another form of corporate or financing transaction. Marvel Maids
              Cleaning may share your personal information in connection with
              the evaluation of and/or entry into such transactions.
            </p>
            <p>
              <strong>Where Required or Authorized by Law</strong>
            </p>
            <p>
              Marvel Maids Cleaning may also disclose your personal information where
              authorized or required by law. For example, we may disclose your
              personal information to comply with a subpoena, in response to a
              law enforcement body with the lawful authority to obtain the
              information, pursuant to an investigation into the breach of a
              law, or to our legal counsel.
            </p>
            <p>
              <strong>
                SECURITY AND PROTECTION OF YOUR PERSONAL INFORMATION
              </strong>
            </p>
            <p>
              Marvel Maids Cleaning takes reasonable steps to protect your personal
              information. We use security arrangements to protect against
              unauthorized access, collection, use, disclosure, and disposal, in
              a manner appropriate to the sensitivity of the information. As
              well, Marvel Maids Cleaning will use care when destroying or disposing
              of personal information to prevent unauthorized access, use or
              disclosure of any personal information. Marvel Maids Cleaning’s
              employees and independent contractors with access to personal
              information are required to respect the confidentiality of such
              information.
            </p>
            <p>
              The Sites include links to external websites. Once you leave the
              Sites this Policy does not apply. Marvel Maids Cleaning is not
              responsible for the privacy practices, collection of personal
              information, or content of external websites. Marvel Maids Cleaning is
              not responsible for any lost, stolen, or compromised usernames,
              passwords or for any activity on your Account via unauthorized
              password activity. You should take steps to protect against
              authorized access to your Account by, for example, choosing a
              robust password and keeping your login information and password
              private. Marvel Maids Cleaning is not responsible for any failure by you
              to secure your own devices and their access to the Internet or
              your use of public, unsecured networks.
            </p>
            <p>
              Unfortunately, the transmission of information via the Internet
              and mobile platforms is not completely secure. Although we have
              designed features and employed security techniques to protect your
              personal information, we cannot guarantee the security of personal
              information at all times. Any transmission of your personal
              information is at your own risk.
            </p>
            <p>
              <strong>
                STORAGE AND TRANSFERRING OF YOUR PERSONAL INFORMATION
              </strong>
            </p>
            <p>
              Marvel Maids Cleaning stores some business and customer contact
              information on servers in the United States. Marvel Maids Cleaning also
              engages third party service providers which are located outside of
              Canada.
            </p>
            <p>
              As a result, your personal information may be stored and processed
              in the United States. by one of Marvel Maids Cleaning’s third party
              service providers and may be subject to the laws and access by
              government or regulatory organizations in the United States. If
              you have any questions about Marvel Maids Cleaning’s policies regarding
              service providers outside of Canada, you can contact the Marvel Maids
              Cleaning Privacy Officer at the contact information listed in this
              Policy.
            </p>
            <p>
              <strong>RETENTION OF YOUR PERSONAL INFORMATION</strong>
            </p>
            <p>
              Information collected by Marvel Maids Cleaning is maintained in
              accordance with applicable privacy legislation and Marvel Maids
              Cleaning’s retention policies and practices. Generally, Marvel Maids
              Cleaning stores your personal information for as long as it is
              reasonably necessary to fulfill the purposes, we collected it for,
              except as otherwise permitted or required by applicable law or
              regulation. Under some circumstances we may anonymize or aggregate
              your personal information so that it can no longer be associated
              with you. We reserve the right to use such anonymous and
              de-identified data for any legitimate business purpose without
              further notice to you or your consent.
            </p>
            <p>
              <strong>CHILDREN UNDER THE AGE OF 13</strong>
            </p>
            <p>
              The Sites are not intended for children under 13 years of age. No
              one under age 13 may provide any personal information to or on the
              Sites. If we learn we have collected or received personal
              information from a child under 13 without verification of parental
              consent, we will delete that information. If you believe we might
              have any personal information from or about a child under 13,
              please contact the Marvel Maids Cleaning Privacy Officer at the contact
              information listed in this Policy.
            </p>
            <p>
              <strong>
                CORRECTION AND ACCESS TO YOUR PERSONAL INFORMATION
              </strong>
            </p>
            <p>
              All personal information in your Account and user profile is
              accessible by you. You can make changes to certain personal
              information that Marvel Maids Cleaning holds about you, such as your
              contact information, by editing the information in your Account.
              You are responsible for keeping the personal information in your
              Account up to date and accurate. Certain minimum personal
              information must be input in your Account in order to use certain
              features of the Sites. You can challenge the accuracy and
              completeness of your personal information in the possession and
              control of Marvel Maids Cleaning. In appropriate circumstances, Marvel Maids
              Cleaning will amend the information. It is your responsibility to
              provide any updates to your personal information to Marvel Maids
              Cleaning in writing.
            </p>
            <p>
              You may request access to your personal information and/or
              correction of that information by contacting Marvel Maids Cleaning in
              writing at the contact information noted below, with sufficient
              detail to enable Marvel Maids Cleaning to identify the personal
              information being sought. When you contact Marvel Maids Cleaning, we may
              ask for further information to confirm your identity and the
              nature of the information being sought.
            </p>
            <p>
              After we receive your request for access to personal information,
              Marvel Maids Cleaning will provide you with an estimate of when you can
              expect a response. In some cases, Marvel Maids Cleaning may need
              additional time to respond to a request, in which case we will
              provide you with written notice of the extension. Please note that
              in some cases, Marvel Maids Cleaning may not provide access to personal
              information that we hold about you, such as where the denial of
              access is authorized by law. There are also cases where Marvel Maids
              Cleaning may be legally required to refuse access to personal
              information. If Marvel Maids Cleaning denies your request for access to
              personal information, we will advise you of the reason for the
              refusal, and will provide the name, title, and contact information
              of the designated person who can address the refusal.
            </p>
            <p>
              Marvel Maids Cleaning may charge a reasonable fee according to the cost
              required to retrieve and provide access to the requested
              information. We may provide an estimate of the fee in advance and
              in some cases, will require a deposit for all or part of the fee.
            </p>
            <p>
              <strong>QUESTIONS AND CONCERNS</strong>
            </p>
            <p>
              Questions or concerns regarding this Policy, including the
              collection of your personal information, can be directed to the AG
              Pro Cleaning Privacy Officer, who is responsible for ensuring AG
              Pro Cleaning’s compliance with this Policy. You can contact the
              Privacy Officer using any of the following methods:
            </p>
            <p>
              <strong>Mailing Address:</strong>
            </p>
            <p>
              Marvel Maids Cleaning Services
              <br />
              103-2455 Dollarton Highway North Vancouver, BC V7H 0A2
              <br />
              CANADA <br />
              Attention: Chief Privacy Officer.
            </p>
            <p>
              <strong>Email:</strong>
            </p>
            <p>contact@Marvel Maids Cleaning.com</p>
            <p>
              Marvel Maids Cleaning takes any complaint about our privacy practices
              seriously. Marvel Maids Cleaning will investigate all complaints. If AG
              Pro Cleaning finds a complaint justified, we will take the
              necessary steps to resolve it. The complainant will be informed of
              the outcome of the investigation regarding any complaint. If you
              are not satisfied with Marvel Maids Cleaning’s response to a complaint,
              you may have options to exercise various complaint procedures,
              including with the relevant Privacy Commissioner:
            </p>
            <p>
              The Office of the Information and Privacy Commissioner for
              <br />
              British Columbia
              <br />
              www.oipc.bc.ca
            </p>
            <p>
              The Office of the Information and Privacy Commissioner of
              <br />
              Alberta
              <br />
              www.oipc.ab.ca
            </p>
            <p>
              Office of the Privacy Commissioner of Canada
              <br />
              www.priv.gc.ca
            </p>
            <p>
              <strong>CHANGES TO THE POLICY</strong>
            </p>
            <p>
              Marvel Maids Cleaning may update this Policy from time to time by
              posting a new version of the Policy on the Sites. If there are
              significant changes made to the Policy, we may notify Users in
              advance by e-mail. Marvel Maids Cleaning’s collection, use, disclosure,
              and retention of your personal information will be governed by the
              version of the Policy in effect at that time. We suggest that you
              review this Policy periodically.
            </p>
            <p>Last updated: 18th March 2021</p>
          </div>
        </div>
        <div className="col-3"></div>
      </div>
    </div>
  );
}
