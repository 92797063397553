import React, { useState, useEffect } from "react";
import Layout from "../../../layout";
import { AddNewBlogService, updateBlogService } from "../../../config/blog";
import { toast } from "react-hot-toast";
import LoaderSvg from "../../../images/loader.svg";
import { useNavigate, Link } from "react-router-dom";
import { validateField } from "../../../helpers";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { assetsURL } from "../../../config/axiosConfig";
import { useDispatch } from "react-redux";
import { getBlogsAction } from "../../../redux/actions/auth";
import { registerAdminService } from "../../../config/user";
const AddAdmin = ({
  edit = false,
  activeItem = false,
  setCloseModal = () => {},
  onGetAllBlogs = () => {},
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (e) => {
    e.preventDefault();
    let validate = true;

    if (email.trim() === "") {
      validateField("email", "Email field is required");
      validate = false;
    }
    if (firstName.trim() === "") {
      validateField("firstName", "Firstname field is required");
      validate = false;
    }

    if (lastName.trim() === "") {
      validateField("lastName", "Lastname field is required");
      validate = false;
    }

    if (!validate) {
      toast("Please fill required fields");
      return;
    }

    try {
      setLoader(true);
      let data = {
        firstName,
        lastName,
        email,
      };

      const res = await registerAdminService(data);

      if (res.data.success) {
        setLoader(false);

        toast.success("Admin Added Successfully");
        setLoader(false);
        // dispatch(getBlogsAction());
        navigate("/admin/admins-list");
      }
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  const render = () => {
    return (
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <form
                class="forms-sample"
                onSubmit={(e) => {
                  onSubmit(e);
                }}
              >
                <div class="form-group">
                  <label for="firstNameInput">Firstname</label>
                  <input
                    type="text"
                    class="form-control"
                    id="firstNameInput"
                    placeholder="Enter firstname"
                    value={firstName}
                    name="firstname"
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </div>
                <div class="form-group">
                  <label for="lastNameInput">Lastname</label>
                  <input
                    type="text"
                    class="form-control"
                    id="lastNameInput"
                    placeholder="Enter lastname"
                    value={lastName}
                    name="lastname"
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </div>
                <div class="form-group">
                  <label for="emailInput">Email</label>
                  <input
                    type="email"
                    class="form-control"
                    id="emailInput"
                    placeholder="Enter email"
                    value={email}
                    name="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>

                <button
                  type="submit"
                  class="btn btn-primary btn-dashboard-add me-2"
                >
                  {loader ? (
                    <img src={LoaderSvg} style={{ padding: "0 7px" }} />
                  ) : (
                    "Submit"
                  )}
                </button>
                {/* <button class="btn btn-light">Cancel</button> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return !edit ? (
    <Layout
      header="Add blog"
      sideBtn={
        <Link
          to="/blogs-list/admin"
          class="add btn btn-primary todo-list-add-btn"
        >
          {" "}
          Go Back
        </Link>
      }
    >
      {render()}
    </Layout>
  ) : (
    render()
  );
};

export default AddAdmin;
