import publicReq, { privateReq, imageUpload } from "./axiosConfig";

export const AddNewServiceApi = (data) =>
  privateReq.post("/service/add-service", data);
export const getServices = (data = "") =>
  privateReq.get("/service/service-list" + data);
export const getServiceBySlugService = (slug) =>
  privateReq.get("/service/get/" + slug);
export const updateServiceApi = (data) =>
  imageUpload.post("/update-service", data);
export const deleteServiceApi = (data) =>
  privateReq.delete("/delete-service/" + data.id);
