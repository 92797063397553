import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./services.scss";
import { Link } from "react-router-dom";

export default function ServicesBanner() {
  return (
    <>
      <section class="hero-banner">
        <div class="container servicesHeadingBanner">
          <h1>Cleaning Services</h1>
          <p>
            House cleaning services where your cleaning <br />
            teams are fully trained, your cleaning products are green,
            <br /> and your satisfaction is guaranteed, since 2004
          </p>
          <Link to="/online-booking" class="btn btn-primary btn-lg">
            BOOK NOW
          </Link>
        </div>
      </section>


    </>
  );
}
