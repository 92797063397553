import {
  SET_CURRENT_USER,
  REMOVE_CURRENT_USER,
  SET_ALL_USERS,
  SET_BLOGS,
  SET_SERVICES,
} from "./types";
import { getProfile, updateUser, getAllUsers } from "../../config/user";
import { getBlogs } from "../../config/blog";
import { getServices } from "../../config/service";

export const setCurrentUser = (data) => async (dispatch) => {
  try {
    const res = await getProfile();

    dispatch({
      type: SET_CURRENT_USER,
      payload: res.data.user,
    });
  } catch (e) {
    console.log(e);
  }
};
export const setAllUsers = (data) => async (dispatch) => {
  try {
    const res = await getAllUsers(data);

    dispatch({
      type: SET_ALL_USERS,
      payload: { users: res.data.users, total: res.data.total },
    });
  } catch (e) {
    console.log(e);
  }
};
export const updateCurrentUser = (data) => async (dispatch) => {
  try {
    const res = await updateUser(data);

    dispatch({
      type: SET_CURRENT_USER,
      payload: res.data.user,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getBlogsAction = (data) => async (dispatch) => {
  try {
    const res = await getBlogs();

    dispatch({
      type: SET_BLOGS,
      payload: res.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const getServicesAction = (data) => async (dispatch) => {
  try {
    const res = await getServices();

    dispatch({
      type: SET_SERVICES,
      payload: res.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const logOutUser = () => async (dispatch) => {
  await sessionStorage.removeItem("token");

  dispatch({
    type: REMOVE_CURRENT_USER,
    payload: null,
  });
};
