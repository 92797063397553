import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Row, Col, Button, Toast } from "react-bootstrap";
import "./register.scss";
import { RegisterService } from "../../config/user";
import { validateField } from "../../helpers";
import { toast } from "react-hot-toast";
const Register = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState("");
  const [role, setRole] = useState("");
  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    let validate = true;
    if (firstName.trim() === "") {
      validateField("firstName", "First name field is required");
      validate = false;
    }
    if (lastName.trim() === "") {
      validateField("lastName", "Last name field is required");
      validate = false;
    }
    if (email.trim() === "") {
      validateField("email", "Email field is required");
      validate = false;
    }

    if (password.trim() === "") {
      validateField("password", "Password field is required");
      validate = false;
    }

    if (password.trim().length < 8) {
      validateField("password", "Password should be minimum 8 characters");
      validate = false;
    }

    if (!validate) {
      toast.error("Please fill required fields.");

      return;
    }
    try {
      setLoader(true);
      const res = await RegisterService({
        firstName,
        lastName,
        email,
        password,
        // role: "customer"
      });

      if (res.data.status) {
        // sessionStorage.setItem("token", res.data.token);
        // sessionStorage.setItem('role', res.data.role);
        toast.success("Register Succesfully");

        setLoader(false);

        window.location.href = "/login";
      }
    } catch (err) {
      setLoader(false);
      if (err.response.data.message) {
        toast.error(err.response.data.message);
      }
    }
  };

  return (
    <div className="register-styles">
      <Row>
        <Col md={12} xs={12}>
          <h2>Create Account</h2>
          <Form className="register-form" onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="text"
                placeholder="First Name"
                name="firstName"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="text"
                placeholder="Last Name"
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                name="lastName"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="email"
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                name="email"
                autoComplete="newEmail"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="password"
                placeholder="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                name="password"
                autoComplete="newPassword"
              />
            </Form.Group>
            <Button className="btn-register" variant="primary" type="submit">
              Create
            </Button>{" "}
            <Link to="/login">Already have an account? Login</Link>
          </Form>
        </Col>
      </Row>
    </div>
  );
};
export default Register;
