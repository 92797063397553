import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./single-service.scss";
import { assetsURL } from "../../../config/axiosConfig";
import { useNavigate } from "react-router-dom";

export default function ServiceSingleHero({ service }) {
  const navigate = useNavigate();
  return (
    <Container className="service-single-hero">
      <Row style={{ paddingBottom: "10px", paddingTop: "20px" }}>
        <Col md={6} xs={12} className="text-left">
          <h3>{service.cleaningType} Services - Voted Best in Class</h3>
          <p dangerouslySetInnerHTML={{ __html: service.desc }}></p>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate("/online-booking");
            }}
            className="button-global-booking"
          >
            BOOK NOW
          </button>
        </Col>
        <Col md={6} xs={12} className="right-img">
          <Image src={assetsURL + service.image} className="hero-image" />
        </Col>
      </Row>
    </Container>
  );
}
