import React from "react";
import "./PremimusServices.scss";
import { useNavigate } from "react-router-dom";
export default function PremimusServices() {
  const navigate = useNavigate();
  return (
    <div className="premium-service-wrapper">
      <section class="container mt-5 py-5 ">
        <h2 className="heading-top">
          Discover our Premium House Cleaning Services
        </h2>
        <div class="row">
          <div class="col-lg-6 d-flex align-items-center">
            <img
              src="assets/images/banner-3.png"
              class="img-fluid"
              alt="Company Image"
              style={{ borderRadius: "10px", alignSelf: "center" }}
            />
          </div>
          <div class="col-lg-6">
            <div class="PremimServices-section">
              <h2 className="heading-bottom">Marvel Maids Cleaning Services</h2>
              <p>
                Our fully-trained, professional cleaning services teams will
                help create a sparkling clean home, the Marvel Maids Cleaning Way. We
                are a premier cleaning company that offers cleaning service in
                Vancouver, Calgary cleaning services and also our cleaning
                service in Toronto. At Marvel Maids Cleaning we believe a clean home
                or workplace doesn’t require harming the environment. Our
                professional cleaning services in the above areas include:
              </p>
              <div className="text-start">
                <ul>
                  <li>House cleaning services</li>
                  <li>Apartment and condo cleaning services</li>
                  <li>Move in move out cleaning services</li>
                  <li>Regular house cleaning and maid services</li>
                  <li>Office cleaning services</li>
                  <li>Airbnb cleaning services</li>
                  <li>Carpet cleaning services</li>
                  <li>Post-construction cleaning services</li>
                </ul>
              </div>

              <button
                onClick={(e) => navigate("/online-booking")}
                class="btn btn-primary"
              >
                BOOK NOW
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
