import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import MegaMenu from "./components/MegaMenu";
import Footer from "./components/Footer";
import RootStack from "./screens/RootStack";
import { Provider } from "react-redux";
import store from "../src/redux/store";
import { Toaster } from "react-hot-toast";
import "./assets/scss/style.scss";
import MegaMenuNew from "./components/MegamenuNew";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Provider store={store}>
          <MegaMenuNew />
          <RootStack />
          <Toaster
            containerStyle={{
              zIndex: 999999,
            }}
            toastOptions={{
              position: "top-right",
            }}
          />
          <Footer />
        </Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
