import React from "react";
import HomeHero from "../../components/sections/home/hero/HomeHero";
import Card from "../../components/sections/home/card/Card";
import About from "../../components/sections/home/about/About";
import Certificate from "../../components/sections/home/certificate/Certificate";
import PremimusServices from "../../components/sections/home/premiumservice/PremimusServices";
const Home = () => {
  return (
    <div>
      <HomeHero />
      {/* <Card /> */}
      <Certificate />
      <About />
      <PremimusServices />
    </div>
  );
};

export default Home;
