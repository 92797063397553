export const validateField = (field, message = "This field is required") => {
    function insertAfter(newNode, existingNode) {
        existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
    }
    function removeElementsByClass(curField, className) {
        console.log(curField)
        const elements = curField.parentNode.getElementsByClassName(className);
        while (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
        }
    }
    let curField = document.getElementsByName(field)[0]
    removeElementsByClass(curField, "invalid-feedback")
    window.scrollTo(500, 0);
    curField.classList.add("border-danger")
    curField.classList.add("border-danger")
    let error = document.createElement('div');
    error.classList.add("invalid-feedback", "text-start")
    error.textContent = message;
    error.style.display = "block"
    insertAfter(error, curField);
}


export const removeValidation = () => {
    let invalidFeedback = document.getElementsByClassName("invalid-feedback")
    let borderDanger = document.getElementsByClassName("border-danger")

    if (borderDanger.length > 0) {
        for (let i = 0; i < borderDanger.length; i++) {
            borderDanger[i].classList.remove("border-danger")
        }
    }
    if (invalidFeedback.length > 0) {
        for (let i = 0; i < invalidFeedback.length; i++) {
            invalidFeedback[i].textContent = ""
            invalidFeedback[i].style.display = "none"
        }
    }
}