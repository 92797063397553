import React from 'react';

const MyAccount = () => {

    return (
        <div className='contact-styles'>
            My Account
        </div>
    )
}
export default MyAccount;