import React, { useEffect, useState } from "react";
import { updateBookingService } from "../../config/booking";
import SuccessImage from "../../images/booking/success.jpg";
import FailedImage from "../../images/booking/failed.png";
import "./booking.scss";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const BookingResult = () => {
  const navigate = useNavigate();
  const [message, setMessage] = React.useState("Processing payment...");
  const [status, setStatus] = useState();

  const updateBooking = async (status) => {
    try {
      const bookingId = new URLSearchParams(window.location.search).get("id");
      const res = await updateBookingService({ id: bookingId, status });
      if (res.data.status) {
        if (res.data.paymentStatus === "succeeded") {
          setStatus(true);
        }
        setMessage("Order placed! You will receive an email confirmation.");
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      updateBooking("success");
      setStatus(true);
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      updateBooking("canceled");
      setStatus(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);
  const Loader = () => (
    <svg
      svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      version="1.0"
      width="200px"
      height="200px"
      viewBox="0 0 128 128"
      space="preserve"
    >
      <g>
        <path
          d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z"
          fill="#e6e6e6"
        />
        <path
          d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z"
          fill="#e0e0e0"
          transform="rotate(15 64 64)"
        />
        <path
          d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z"
          fill="#d9d9d9"
          transform="rotate(30 64 64)"
        />
        <path
          d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z"
          fill="#d1d1d1"
          transform="rotate(45 64 64)"
        />
        <path
          d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z"
          fill="#c8c8c8"
          transform="rotate(60 64 64)"
        />
        <path
          d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z"
          fill="#bfbfbf"
          transform="rotate(75 64 64)"
        />
        <path
          d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z"
          fill="#b5b5b5"
          transform="rotate(90 64 64)"
        />
        <path
          d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z"
          fill="#a9a9a9"
          transform="rotate(105 64 64)"
        />
        <path
          d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z"
          fill="#a9a9a9"
          transform="rotate(120 64 64)"
        />
        <path
          d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z"
          fill="#939393"
          transform="rotate(135 64 64)"
        />
        <path
          d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z"
          fill="#868686"
          transform="rotate(150 64 64)"
        />
        <path
          d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z"
          fill="#7a7a7a"
          transform="rotate(165 64 64)"
        />
        <path
          d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z"
          fill="#6e6e6e"
          transform="rotate(180 64 64)"
        />
        <path
          d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z"
          fill="#626262"
          transform="rotate(195 64 64)"
        />
        <path
          d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z"
          fill="#565656"
          transform="rotate(210 64 64)"
        />
        <path
          d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z"
          fill="#4b4b4b"
          transform="rotate(225 64 64)"
        />
        <path
          d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z"
          fill="#3f3f3f"
          transform="rotate(240 64 64)"
        />
        <path
          d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z"
          fill="#343434"
          transform="rotate(255 64 64)"
        />
        <path
          d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z"
          fill="#2a2a2a"
          transform="rotate(270 64 64)"
        />
        <path
          d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z"
          fill="#202020"
          transform="rotate(285 64 64)"
        />
        <path
          d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z"
          fill="#171717"
          transform="rotate(300 64 64)"
        />
        <path
          d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z"
          fill="#101010"
          transform="rotate(315 64 64)"
        />
        <path
          d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z"
          fill="#090909"
          transform="rotate(330 64 64)"
        />
        <path
          d="M61.5 0h5a25.8 25.8 0 0 1 5.25 9 26.35 26.35 0 0 1 1.3 10.8l-5 .1A23.38 23.38 0 0 0 67 10a32.3 32.3 0 0 0-5.5-10z"
          fill="#000000"
          transform="rotate(345 64 64)"
        />
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0 64 64;15 64 64;30 64 64;45 64 64;60 64 64;75 64 64;90 64 64;105 64 64;120 64 64;135 64 64;150 64 64;165 64 64;180 64 64;195 64 64;210 64 64;225 64 64;240 64 64;255 64 64;270 64 64;285 64 64;300 64 64;315 64 64;330 64 64;345 64 64"
          calcMode="discrete"
          dur="2160ms"
          repeatCount="indefinite"
        ></animateTransform>
      </g>
    </svg>
  );
  return (
    <div className="booking-wrapper ">
      {status !== undefined || status !== null ? (
        <>
          <img
            style={{
              width: "20%",
              height: "20%",
            }}
            src={status ? SuccessImage : FailedImage}
            alt={status ? "success" : "failed"}
          />
          <h2
            style={{
              textAlign: "center",
            }}
          >
            {message}
          </h2>
          <Button
            onClick={() => navigate("/bookings")}
            className="btn-booking mt-4"
          >
            Check bookings
          </Button>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default BookingResult;
