import Modal from "react-modal";

const customStyles = {
  overlay: {
    position: "fixed",
    inset: 0,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    width: "100%",
    height: "100%",
    top: 0,
    zIndex: 9999999,
    left: 0,
  },
  content: {
    maxWidth: "50%",
    minWidth: "50%",
    margin: "0 auto",
  },
};

export default function AGProModal({
  children,
  state,
  stateFunc,
  title,
  styles = {},
}) {
  return (
    <Modal
      isOpen={state}
      style={{ ...customStyles, ...styles }}
      contentLabel="Example Modal"
    >
      <h2>{title}</h2>
      <a
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          display: "inline-block",
          fontSize: 30,
          color: "#000",
        }}
        href="#"
        onClick={(e) => {
          e.preventDefault();
          stateFunc(false);
        }}
      >
        <i class="menu-icon mdi mdi-close"></i>
      </a>

      {children}
    </Modal>
  );
}
