import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./services.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { assetsURL } from "../../../config/axiosConfig";

export default function ServiceOffered() {
  const { services } = useSelector((state) => state.auth);
  return (
    <Container className="services-offered">
      <Row style={{ paddingBottom: "75px" }}>
        {services.length > 0 &&
          services.map((service, key) => (
            <Col md={3} xs={12}>
              <div className="col-div">
                <Image
                  src={assetsURL + service.image}
                  className="service1-image"
                  height={100}
                  width={100}
                />
                <span className="mt-4">{service.title}</span>
              </div>
              <div className="col-link">
                <Link className="me-2" to={`/service/${service.slug}`}>
                  {service.cleaningType}
                </Link>
                <Image
                  src="../assets/images/arrow-icon.svg"
                  height={20}
                  width={20}
                />
              </div>
            </Col>
          ))}

        {/* <Col md={3} xs={12}>
                    <div className="col-div">
                        <Image src="assets/images/service2.webp" className="service1-image" height={100} width={100} />
                        <span>Condo and Apartment Cleaning Services</span>
                        <div className="col-link">
                            <Link to="#" >Condo Cleaning Services </Link>
                            <Image src="../assets/images/arrow-icon.svg" height={20} width={20} />
                        </div>
                    </div>
                </Col>
                <Col md={3} xs={12}>
                    <div className="col-div">
                        <Image src="assets/images/service3.webp" className="service1-image" height={100} width={100} />
                        <span>Office Cleaning <br />Services</span>
                        <div className="col-link">
                            <Link to="#" >Office Cleaning Services</Link>
                            <Image src="../assets/images/arrow-icon.svg" height={20} width={20} style={{ marginLeft: '5px' }} />
                        </div>
                    </div>

                </Col>
                <Col md={3} xs={12}>
                    <div className="col-div">
                        <Image src="assets/images/service5.webp" className="service1-image" height={100} width={100} />
                        <span>Spring Cleaning<br /> Services</span>
                        <div className="col-link">
                            <Link to="#" >Spring Cleaning Services</Link>
                            <Image src="../assets/images/arrow-icon.svg" height={20} width={20} />
                        </div>
                    </div>
                </Col>
            </Row>
            <Row >
                <Col md={3} xs={12} >
                    <div className="col-div">
                        <Image src="assets/images/service6.webp" className="service1-image" height={100} width={100} />
                        <span>Move In Cleaning / Move Out Cleaning Services</span>
                        <div className="col-link">
                            <Link to="#" >Move In / Move Out Cleaning Services</Link>
                            <Image src="../assets/images/arrow-icon.svg" height={20} width={20} />
                        </div>
                    </div>
                </Col>
                <Col md={3} xs={12}>
                    <div className="col-div">
                        <Image src="assets/images/service7.webp" className="service1-image" height={100} width={100} />
                        <span>Short-Term Rental & Airbnb Cleaning Services</span>
                        <div className="col-link">
                            <Link to="#" >Short-Term Rental Cleaning<br /> Services </Link>
                            <Image src="../assets/images/arrow-icon.svg" height={20} width={20} />
                        </div>
                    </div>
                </Col>
                <Col md={3} xs={12}>
                    <div className="col-div">
                        <Image src="assets/images/service8.webp" className="service1-image" height={100} width={100} />
                        <span>Post-Construction Cleaning Services</span>
                        <div className="col-link">
                            <Link to="#" >Post-Construction Cleaning <br />Services </Link>
                            <Image src="../assets/images/arrow-icon.svg" height={20} width={20} />
                        </div>
                    </div>
                </Col>
                <Col md={3} xs={12}>
                    <div className="col-div">
                        <Image src="assets/images/service9.webp" className="service1-image" height={100} width={100} />
                        <span>Carpet Cleaning Services Vancouuver</span>
                        <div className="col-link">
                            <Link to="#" >Carpet Cleaning Services Vancouuver </Link>
                            <Image src="../assets/images/arrow-icon.svg" height={20} width={20} />
                        </div>
                    </div>
                </Col> */}
      </Row>
    </Container>
  );
}
