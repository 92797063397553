import Accordion from "react-bootstrap/Accordion";
import "./Faq.scss";

const faqs = [
  {
    id: 1,
    question: "What happens if my cleaners require more or less time?",
    ans: "If you cleaners have completed the job before the allotted time expires, we will absolutely adjust the price. At Marvel Maids Cleaning, you will never pay more than you need to. If the cleaners require more time than allotted our management will reach out 1 hr before the time is set to expire. We will then ask if you would like to add more time. You will always be asked and kept up to date on the progress of your clean.",
  },
  {
    id: 2,
    question: "What is the difference between a deep clean and move out clean?",
    ans: "A deep clean is a comprehensive deep clean it can also include the interior of fridge, dishwasher and oven. A move out clean is also a comprehensive deep clean that includes interior clean of all appliances and all cabinetry (Kitchen, Bathrooms) as a standard part of the clean.",
  },
  {
    id: 3,
    question: "Why do move out clean cost more?",
    ans: "Move out clean are extremely laborious cleans. They require more power and supplies to complete the clean properly.",
  },
  {
    id: 4,
    question: "Do I save money if I get more cleans?",
    ans: "Yes. The more regular you clean, bigger the savings! We offer rates for different frequencies.",
  },
  {
    id: 5,
    question: "Will I have the same team every time?",
    ans: "Yes. At Marvel Maids Cleaning, we strive to provide stability and familiarity it is not only beneficial for you to have the same team but us a company as well.",
  },
  {
    id: 6,
    question: "Do I need to supply anything?",
    ans: "We supply everything. We take care of it all. However if there are special cleaners you would like us to use we are more than happy to use your products for your specific needs.",
  },
  {
    id: 7,
    question: "Professional cleaning company VS independent cleaner",
    ans: "It is safer for homeowners to work with a licensed cleaning agency than with a private cleaner. Cleaning company allows only bonded, fully trained and background checked cleaners to work, completely protects homeowners from any damages, scam, theft, injuries and other issues.",
  },
  {
    id: 8,
    question: "Cleaning Services hourly rate. How much does it costs?",
    ans: `The cost of cleaning services in Canada varies depending on several factors, such as:<br></br>
    — Location,<br></br>
    — Size of the property,<br></br>
    — Frequency,<br></br>
    — Type of cleaning services,<br></br>
    — Special requests that require more time.<br></br>
    
    On average, you can expect to pay anywhere from $40 to $75 per hour for basic cleaning services. Whether it is considered expensive or not depends on individual circumstances and personal opinions. Some people may find the cost reasonable and affordable, while others may consider it expensive. Post-construction cleaning or specialized services will cost more.<br></br>
    
    In addition to the factors mentioned earlier, the cost of cleaning services can also vary based on the type of cleaning needed and the experience and qualifications of the cleaning professionals. For example, a one-time deep cleaning service for a large house may cost more than regular cleaning services for a smaller apartment.`,
  },
];
function Faq() {
  return (
    <>
      <div className="faq-hero-wrapper">
        <div class="container">
          <h1>Frequently Asked Questions</h1>
        </div>
      </div>
      <div className="p-5">
        <Accordion defaultActiveKey="0">
          {faqs.map((faq, idx) => (
            <Accordion.Item eventKey={String(idx)}>
              <Accordion.Header className="accordian-head">
                <h3>{faq.question}</h3>
              </Accordion.Header>
              <Accordion.Body
                dangerouslySetInnerHTML={{ __html: faq.ans }}
                className="text-start"
              ></Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </>
  );
}

export default Faq;
