import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <div>
      <footer>
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-sm-6">
              <div class="single-box">
                <img src="img/logo.png" alt="" />
                <p>
                  Marvel Maids: Your Premier Cleaning Company. We provide exceptional
                  cleaning services tailored to your needs, ensuring spotless
                  and immaculate spaces.
                </p>
                <h3>We Accept</h3>
                <div class="card-area">
                  <i class="fa-brands fa-cc-visa"></i>
                  <i class="fa-solid fa-credit-card"></i>
                  <i class="fa-brands fa-cc-mastercard"></i>
                  <i class="fa-brands fa-paypal"></i>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="single-box">
                <h2>COMPANY</h2>
                <ul>
                  <li>
                    <Link to="/about-us">About us</Link>
                  </li>
                  <li>
                    <Link to="/blogs">Blogs</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/faq">FAQ</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  {/* <li>
                    <Link to="/Newsletter">Help Us</Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="single-box">
                <h2>CLEANING SERVICES</h2>
                <ul>
                  <li>
                    <Link to="/services">
                      Vancouver House Cleaning Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/services">Calgary House Cleaning Services</Link>
                  </li>
                  <li>
                    <Link to="/services">Toronto House Cleaning Services</Link>
                  </li>
                  <li>
                    <Link to="/services">Cleaning Services</Link>
                  </li>
                  <li>
                    <Link to="/services">Cleaning Services FAQ</Link>
                  </li>
                  <li>
                    <Link to="/services">Cleaning jobs</Link>
                  </li>
                  {/* <li>
                    <Link to="/term-of-use">Terms of Cleaning Services</Link>
                  </li> */}
                </ul>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6">
              <div class="single-box">
                {/* <h2>Newsletter</h2>
                                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur doloremque earum
                                    similique fugiat nobis. Facere?</p>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" placeholder="Recipient's username"
                                        aria-label="Enter your Email ..." aria-describedby="basic-addon2" />
                                    <span class="input-group-text" id="basic-addon2"><i
                                        class="fa fa-long-arrow-right"></i></span>
                                </div> */}
                <h2>Follow us on</h2>
                <p class="socials">
                  <i class="fa-brands fa-facebook"></i>
                  <i class="fa-brands fa-instagram"></i>
                  <i class="fa-brands fa-pinterest"></i>
                  <i class="fa-brands fa-twitter"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
export default Footer;
