import React, { useEffect, useState } from "react";
import Layout from "../../../layout";
import {
  getBookingsService,
  updateBookingStatusService,
} from "../../../config/booking";
import moment from "moment";
import { useToaster } from "react-hot-toast";
import { useSelector } from "react-redux";

const sqFtArr = [
  "0-1000",
  "1000-1500",
  "1500-2000",
  "2000-2500",
  "2500-3000",
  "3000-3500",
  "3500-4000",
  "4000-4500",
  "4500-5000",
  "5000-5500",
  "5500-6000",
  "6000-6500",
  "6500-7000",
  "7000-7500",
];
const BookingsAdmin = () => {
  const [bookings, setBookings] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [total, setTotal] = useState(0);
  const toast = useToaster();
  const [page, setPage] = useState(1);
  const getBookings = async () => {
    try {
      const res = await getBookingsService(`?page=${page}`);
      if (res.data) {
        setBookings(res.data.list);
        setTotal(res.data.total);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getBookings();
  }, [page]);

  const updateStatus = async (status, id) => {
    try {
      const res = await updateBookingStatusService({ status, _id: id });
      if (res.data.status) {
        getBookings();
        toast.success(res.data.message);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const renderBooking = (item, key) => {
    let addAreas =
      [...item.addAreas]?.map((i) => i.value.split("-").join(" ")).join(", ") ||
      "N/A";
    return (
      <tr>
        <td className="text-capitalize">
          {user && user.role === "super-admin" && item.status === "pending" ? (
            <select
              className="form-control js-example-basic-single w-100"
              onChange={(e) => {
                updateStatus(e.target.value, item._id);
              }}
              name="paymentStatus"
            >
              <option value="">Select Status</option>
              <option selected={item.status === "pending"} value={"pending"}>
                Pending
              </option>
              <option
                selected={item.status === "completed"}
                value={"completed"}
              >
                Completed
              </option>
              <option selected={item.status === "canceled"} value={"canceled"}>
                Canceled
              </option>
            </select>
          ) : (
            item.status
          )}
        </td>
        <td className="text-capitalize">
          {moment(item.created).format("DD/MM/YYYY")}
        </td>
        <td className="text-capitalize">
          {moment(item.date).format("DD/MM/YYYY HH:mm")}
        </td>
        <td>{item.price}</td>
        <td>{item.paymentId || "N/A"}</td>
        <td className="text-capitalize">{item.paymentStatus}</td>
        <td className="text-capitalize">{item.city}</td>
        <td className="text-capitalize">{sqFtArr[+item.sqFt - 1]}</td>
        <td className="text-capitalize">
          {item.appliances?.join(", ") || "N/A"}
        </td>
        <td className="text-capitalize">{item.addOns?.join(", ") || "N/A"}</td>
        <td className="text-capitalize">{addAreas}</td>
        <td className="text-capitalize">{item.carpetedBedrooms || "N/A"}</td>
        <td className="text-capitalize">{item.carpetedLivingRooms || "N/A"}</td>
      </tr>
    );
  };

  return (
    <Layout header="Bookings" hideNav={true}>
      <div className=" mt-3">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <h4 className="card-title text-start">Bookings List</h4>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Delivery Status</th>
                      <th>Created on</th>
                      <th>Appointment date</th>
                      <th>Cost (CAD)</th>
                      <th>
                        Payment ID{" "}
                        {user && user.role === "super-admin" && "(Stripe)"}
                      </th>
                      <th>Payment status</th>
                      <th>City</th>
                      <th>Sq. Ft.</th>
                      <th>Appliances</th>
                      <th>Add ons</th>
                      <th>Add. areas</th>
                      <th>Carpeted Bedrooms</th>
                      <th>Carpeted Living Rooms</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookings &&
                      bookings.length > 0 &&
                      bookings.map((i, key) => {
                        return renderBooking(i, key);
                      })}
                  </tbody>
                </table>
                {total > 20 && (
                  <div>
                    <ul className="pagination">
                      {page > 1 ? (
                        <li>
                          <a onClick={() => setPage((page) => page - 1)}>
                            Prev
                          </a>
                        </li>
                      ) : null}
                      <li>
                        <a onClick={() => setPage(page)}>{page}</a>
                      </li>
                      {page * 20 < total ? (
                        <li>
                          <a onClick={() => setPage((page) => page + 1)}>
                            {page + 1}
                          </a>
                        </li>
                      ) : null}
                      {(page + 2) * 20 < total ? (
                        <li>
                          <a onClick={() => setPage((page) => page + 2)}>
                            {page + 2}
                          </a>
                        </li>
                      ) : null}
                      {(page + 3) * 20 < total ? (
                        <li>
                          <a onClick={() => setPage((page) => page + 3)}>
                            {page + 3}
                          </a>
                        </li>
                      ) : null}
                      {page * 20 < total ? (
                        <li>
                          <a onClick={() => setPage((page) => page + 1)}>
                            Next
                          </a>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BookingsAdmin;
