import React from "react";
import "./main.scss";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { assetsURL } from "../../../config/axiosConfig";

export default function Main() {
  const { blogs } = useSelector((state) => state.auth);
  return (
    <Container className="blogs-styles-wrapper mt-4">
      <h3 className="text-start">Our Blogs</h3>
      <Row>
        {blogs.length > 0 &&
          blogs.map((blog, key) => (
            <Col md={6} xs={12} className="mt-2" key={blog.title}>
              <Link to={`/blog-detail/${blog.slug}`}>
                <Card style={{ width: "100%" }}>
                  <Card.Img variant="top" src={assetsURL + blog.image} />
                  <Card.Body>
                    <Card.Title>{blog.title}</Card.Title>
                    <Card.Text className="card-date">
                      {moment(blog.createdAt).format("MMMM DD, YYYY")}
                    </Card.Text>
                    <Card.Text
                      dangerouslySetInnerHTML={{
                        __html:
                          blog.desc.length > 100
                            ? blog.desc.slice(0, 100) + "..."
                            : blog.desc,
                      }}
                    ></Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
      </Row>
    </Container>
  );
}
