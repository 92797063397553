import React, { useState, useEffect } from "react";
import Layout from "../../../layout";
import { AddNewServiceApi, updateServiceApi } from "../../../config/service";
import { toast } from "react-hot-toast";
import LoaderSvg from "../../../images/loader.svg";
import { useNavigate, Link } from "react-router-dom";
import { validateField } from "../../../helpers";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { assetsURL } from "../../../config/axiosConfig";
const AddService = ({
  edit = false,
  activeItem = false,
  setCloseModal = () => { },
  onGetAllServices = () => { },
}) => {
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [cleaningType, setCleaningType] = useState("");
  const [cleaningLocation, setCleaningLocation] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [image, setImage] = useState({});
  const [desc, setDesc] = useState(EditorState.createEmpty());
  const [descText, setDescText] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setImage(img);
      setImageUrl(URL.createObjectURL(img));
    }
  };

  useEffect(() => {
    if (edit) {
      setTitle(activeItem.title);
      setCategory(activeItem.category);
      setCleaningLocation(activeItem.cleaningLocation);
      setCleaningType(activeItem.cleaningType);
      setImageUrl(assetsURL + activeItem.image);
      if (activeItem.desc) {
        let descTe = EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(activeItem.desc))
        );

        setDesc(descTe);
        const rawContentState = convertToRaw(descTe.getCurrentContent());
        const markup = draftToHtml(rawContentState);
        setDescText(markup);
      }
    }
  }, []);

  const onEditorStateChange = (editorState) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    setDesc(editorState);
    setDescText(markup);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let validate = true;

    if (title.trim() === "") {
      validateField("title", "Title field is required");
      validate = false;
    }
    if (category.trim() === "") {
      validateField("category", "Category field is required");
      validate = false;
    }
    if (cleaningLocation.trim() === "") {
      validateField("cleaningLocation", "Location field is required");
      validate = false;
    }
    if (cleaningType.trim() === "") {
      validateField("cleaningType", "Type field is required");
      validate = false;
    }
    if (descText.trim() === "") {
      validateField("desc", "Description field is required");
      validate = false;
    }

    if (!validate) {
      toast("Please fill required fields");
      return;
    }

    try {
      setLoader(true);
      let fd = new FormData();
      let data = {
        title,
        desc: descText,
        category,
        cleaningLocation,
        cleaningType,
      };
      if (imageUrl !== null) {
        fd.append("image", image);
      }
      for (let key in data) {
        fd.append(key, data[key]);
      }
      const res = await AddNewServiceApi(fd);

      if (res.data.status) {
        setLoader(false);
        setTitle("");
        setDesc("");
        setCategory("");
        setCleaningLocation("");
        setCleaningType("");
        toast.success("Service Added Successfully");
        setLoader(false);
        navigate("/services");
      }
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };
  const onUpdate = async (e) => {
    e.preventDefault();
    let validate = true;

    if (title.trim() === "") {
      validateField("title", "Title field is required");
      validate = false;
    }

    if (!validate) {
      toast("Please fill required fields");
      return;
    }

    try {
      setLoader(true);
      let fd = new FormData();
      let data = {
        title,
        category,
        desc: descText,
        cleaningLocation,
        cleaningType,
        _id: activeItem._id,
      };
      if (image.name) {
        data = {
          ...data,
          image: image,
        };
      }
      for (let key in data) {
        fd.append(key, data[key]);
      }

      const res = await updateServiceApi(fd);
      if (res.data.status) {
        setLoader(false);
        toast("Service Updated Successfully");
        setCloseModal(false);
        onGetAllServices();
      }
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };

  const render = () => {
    return (
      <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <form
                class="forms-sample"
                onSubmit={(e) => {
                  edit ? onUpdate(e) : onSubmit(e);
                }}
              >
                <div class="form-group">
                  <label for="exampleInputUsername1">Category</label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setCategory(e.target.value);
                    }}
                    name="category"
                    value={category}
                  >
                    <option selected>Select Category</option>
                    <option value="House Cleaning">House Cleaning</option>

                    <option value="Condo & Apartment">Condo & Apartment</option>
                    <option value="Product How-To's">Product How-To's</option>
                    <option value="Airbnb Cleaning Services">
                      Airbnb Cleaning Services
                    </option>
                    <option value="Office Cleaning">Office Cleaning</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="exampleInputUsername1">Cleaning Type</label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setCleaningType(e.target.value);
                    }}
                    name="cleaningType"
                    value={cleaningType}
                  >
                    <option selected>Select Type</option>
                    <option value="Regular Cleaning">Regular Cleaning </option>

                    <option value="One-time Cleaning">One-time Cleaning</option>
                    <option value="Move Out / Move In">
                      Move Out / Move In
                    </option>
                    <option value="Post-Construction">Post-Construction</option>
                    <option value="Add-ons & Special Requests">
                      Add-ons & Special Requests
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="exampleInputUsername1">Location </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setCleaningLocation(e.target.value);
                    }}
                    name="cleaningLocation"
                    value={cleaningLocation}
                  >
                    <option selected>Select Location</option>
                    <option value="Vancouver House Cleaning Services">
                      Vancouver House{" "}
                    </option>
                    <option value=" West Vancouver">West Vancouver</option>
                    <option value="Burnaby">Burnaby</option>
                    <option value="New Westminster">New Westminster</option>
                    <option value="Richmond">Richmond</option>
                    <option value="Coquitlam">Coquitlam</option>
                    <option value="Port Coquitlam & Port Moody">
                      Port Coquitlam & Port Moody{" "}
                    </option>
                    <option value="Toronto House Cleaning Services">
                      Toronto House
                    </option>
                    <option value="Woodbridge">Woodbridge</option>
                    <option value="Markham">Markham</option>
                    <option value="Vaughan">Vaughan</option>
                    <option value="Richmond Hill">Richmond Hill</option>
                    <option value="Thornhill">Thornhill</option>
                    <option value="Calgary House Cleaning Services">
                      Calgary House{" "}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="exampleInputUsername1">Title</label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleInputUsername1"
                    placeholder="Title"
                    value={title}
                    name="title"
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputUsername1">Upload Image</label>
                  <div class="image-input">
                    <input
                      type="file"
                      accept="image/*"
                      id="imageInput"
                      onChange={onImageChange}
                    />
                    <label for="imageInput" class="image-button">
                      <i class="mdi mdi-image"></i> <span>Choose image</span>
                    </label>
                    {imageUrl && <img src={imageUrl} class="image-preview" />}
                    <span class="change-image">Choose different image</span>
                  </div>
                  {/* <img src={imageUrl} width={150} height={150} />

                                    <input type="file" name="myImage" onChange={onImageChange} /> */}
                </div>

                <div class="form-group">
                  <label>Description</label>
                  <Editor
                    wrapperClassName="wrapper-class wrapperClassName"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    editorState={desc}
                    onEditorStateChange={onEditorStateChange}
                  />
                </div>

                <button
                  type="submit"
                  class="btn btn-primary btn-dashboard-add me-2"
                >
                  {loader ? (
                    <img src={LoaderSvg} style={{ padding: "0 7px" }} />
                  ) : (
                    "Submit"
                  )}
                </button>
                {/* <button class="btn btn-light">Cancel</button> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return !edit ? (
    <Layout
      header="Add Service"
      sideBtn={
        <Link
          to="/service-list/admin"
          class="add btn btn-primary todo-list-add-btn"
        >
          {" "}
          Go Back
        </Link>
      }
    >
      {render()}
    </Layout>
  ) : (
    render()
  );
};

export default AddService;
