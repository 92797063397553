import * as React from "react";
import Home from "./home";
import TermOfUse from "./terms";
import Contact from "./contact";
import Register from "./register";
import Login from "./login";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getBlogsAction,
  getServicesAction,
  setCurrentUser,
} from "../redux/actions/auth";
import Services from "./services";
import Service from "./service";
import Blogs from "../components/sections/blogs/Main";
import BlogDetail from "./blogdetail";
import OnlineBooking from "./booking";
import AdminDashboard from "./admindashbaord/index";
import MyAccount from "./myaccount";
import ForgotPassword from "./forgotpassword";
import BlogsListAdmin from "./admindashbaord/blog";
import AddBlog from "./admindashbaord/blog/Add";
import HelpUsScreen from "./helpus";
import PrivacyPolicy from "./privacy";
import AddService from "./admindashbaord/services/Add";
import ServiceListAdmin from "./admindashbaord/services";
import AboutUs from "./about";
import BookingResult from "./booking/results";
import BookingsAdmin from "./admindashbaord/bookings";
import ContactsAdmin from "./admindashbaord/contact";
import Faq from "./faq";
import AddAdmin from "./admindashbaord/add-admin";
import AdminsList from "./admindashbaord/admins";

const RootStack = () => {
  const [user, setUser] = React.useState();
  const checkLoggedInUser = () => {
    setUser(JSON.parse(sessionStorage.getItem("user")));
  };
  React.useEffect(() => {
    // checkLoggedInUser();
    getLists();
    getUser();
  }, []);

  const userRedux = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const getUser = () => {
    let token = sessionStorage.getItem("token");
    if (token !== null) {
      dispatch(setCurrentUser((e) => { }));
    }
  };
  console.log(token !== null && userRedux && userRedux.role === "super-admin");

  const getLists = () => {
    dispatch(getBlogsAction());
    dispatch(getServicesAction());
  };

  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/services" element={<Services />}></Route>
      <Route path="/service/:slug" element={<Service />}></Route>
      <Route path="/blogs" element={<Blogs />}></Route>
      <Route path="/blog-detail/:slug" element={<BlogDetail />}></Route>
      <Route path="/blogs-list/admin" element={<BlogsListAdmin />}></Route>
      <Route path="/add/blog" element={<AddBlog />}></Route>
      <Route
        path="/bookings"
        element={
          token !== null ? <BookingsAdmin /> : <Navigate to="/login" replace />
        }
      ></Route>
      <Route path="/online-booking" element={<OnlineBooking />}></Route>
      <Route
        path="/booking-status"
        element={
          token !== null ? <BookingResult /> : <Navigate to="/login" replace />
        }
      ></Route>
      <Route path="/register" element={<Register />}></Route>
      <Route
        path="/login"
        element={
          <Login checkLoggedInUser={checkLoggedInUser} getUser={getUser} />
        }
      ></Route>
      {/* <Route path="/forget-password" element={<ForgotPassword />}></Route> */}
      <Route path="/contact" element={<Contact />}></Route>
      <Route path="/newsletter" element={<HelpUsScreen />}></Route>
      <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
      <Route
        path="/add/service"
        element={
          token !== null && userRedux && userRedux.role === "super-admin" ? (
            <AddService />
          ) : (
            <Navigate to="/" replace />
          )
        }
      ></Route>
      <Route
        path="/service-list/admin"
        element={
          token !== null && userRedux && userRedux.role === "super-admin" ? (
            <ServiceListAdmin />
          ) : (
            <Navigate to="/" replace />
          )
        }
      ></Route>
      <Route
        path="/contact-list/admin"
        element={
          token !== null && userRedux && userRedux.role === "super-admin" ? (
            <ContactsAdmin />
          ) : (
            <Navigate to="/" replace />
          )
        }
      ></Route>
      <Route
        path="/admin/add-admin"
        element={
          token !== null && userRedux && userRedux.role === "super-admin" ? (
            <AddAdmin />
          ) : (
            <Navigate to="/" replace />
          )
        }
      />
      <Route
        path="/admin/admins-list"
        element={
          token !== null && userRedux && userRedux.role === "super-admin" ? (
            <AdminsList />
          ) : (
            <Navigate to="/" replace />
          )
        }
      />
      <Route path="/about-us" element={<AboutUs />}></Route>
      <Route path="/faq" element={<Faq />}></Route>
      {/* 
            <Route path="/forgot-password" element={<ForgotPassword />}>
            </Route> */}
      {/* {userRedux && (
        <>

            <Route
                path="/contact"
                element={
                    <Contact
                    // checkLoggedInUser={checkLoggedInUser} getUser={getUser} 
                    />
                }
            ></Route>
            <Route
                path="/term-of-use"
                element={<TermOfUse
                //  getUser={getUser}
                />}
            ></Route>
            {/* <Route path="/privacy" element={<Privacy getUser={getUser} />}></Route> */}

      {userRedux && userRedux?.role == "super-admin" && token !== null ? (
        <Route path="/admin" element={<AdminDashboard />} />
      ) : (
        <>
          <Route path="/my-account" element={<MyAccount />}></Route>
        </>
      )}
      {/* <Route path="/*" element={<ErrorPage />}></Route> */}
    </Routes>
  );
};
export default RootStack;
