import React, { useEffect, useState } from "react";
import Layout from "../../../layout";
import { Link } from "react-router-dom";
import { getBlogs, deleteBlogService } from "../../../config/blog";
import { assetsURL } from "../../../config/axiosConfig";
import { getUsersByRoleService } from "../../../config/user";
import moment from "moment";
const AdminsList = () => {
  const [blogs, setBlogs] = useState([]);
  const [search, setSearch] = useState("");

  const [month, setmonth] = useState(new Date().getMonth() + 1);
  const [year, setyear] = useState(new Date().getFullYear());
  const [role, setRole] = useState("super-admin");

  const [totalBlogs, setTotalBlogs] = useState(0);
  const [page, setPage] = useState(1);
  const [activeItem, setActiveItem] = useState({});

  const [closeModal, setCloseModal] = useState(false);

  const onDeleteBlog = async (id) => {
    try {
      let res = await deleteBlogService({ id });
      onGetAllBlogs();
    } catch (e) {
      console.log(e);
    }
  };

  const onGetAllBlogs = async () => {
    try {
      let res = await getUsersByRoleService(`?role=${role}`);
      setBlogs(res.data.users);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    onGetAllBlogs();
  }, [role]);

  const renderBlogs = (item, key) => {
    return (
      <tr>
        <td class="text-capitalize">
          {moment(item.created).format("DD/MM/YYYY")}
        </td>
        <td class="text-capitalize">{item.firstName + " " + item.lastName}</td>
        <td>{item.email}</td>
        <td class="text-capitalize">{item.role}</td>
      </tr>
    );
  };
  return (
    <Layout header="Users List">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <form>
              <div className="row">
                <div className="col-md-6">
                  <div class="form-group">
                    <select
                      class="form-control js-example-basic-single w-100"
                      name="role"
                      onChange={(e) => {
                        setRole(e.target.value);
                      }}
                    >
                      <option value="">Select Role</option>

                      <option
                        value={"super-admin"}
                        selected={role == "super-admin"}
                      >
                        Admins
                      </option>
                      <option value={"customer"} selected={role == "customer"}>
                        Customers
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div className="row">
              <div className="col-md-6">
                <h4 class="card-title text-start">Users List</h4>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Created</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                  </tr>
                </thead>
                <tbody>
                  {blogs &&
                    blogs.length > 0 &&
                    blogs.map((i, key) => {
                      return renderBlogs(i, key);
                    })}
                </tbody>
              </table>
              {/* {totalBlogs > 20 && (
                <div>
                  <ul className="pagination">
                    {page > 1 ? (
                      <li>
                        <a onClick={() => setPage((page) => page - 1)}>Prev</a>
                      </li>
                    ) : null}
                    <li>
                      <a onClick={() => setPage(page)}>{page}</a>
                    </li>
                    {page * 20 < totalBlogs ? (
                      <li>
                        <a onClick={() => setPage((page) => page + 1)}>
                          {page + 1}
                        </a>
                      </li>
                    ) : null}
                    {(page + 2) * 20 < totalBlogs ? (
                      <li>
                        <a onClick={() => setPage((page) => page + 2)}>
                          {page + 2}
                        </a>
                      </li>
                    ) : null}
                    {(page + 3) * 20 < totalBlogs ? (
                      <li>
                        <a onClick={() => setPage((page) => page + 3)}>
                          {page + 3}
                        </a>
                      </li>
                    ) : null}
                    {page * 20 < totalBlogs ? (
                      <li>
                        <a onClick={() => setPage((page) => page + 1)}>Next</a>
                      </li>
                    ) : null}
                  </ul>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminsList;
