import React from "react";
import "./About.scss";
import { useNavigate } from "react-router-dom";
export default function About() {
  const navigate = useNavigate();
  return (
    <div className="about-wrapper">
      <section class="container mt-3 ">
        <div class="row">
          <div class="col-lg-6">
            <div class="about-section">
              <h2>A company that cares.</h2>
              <p>
                Marvel Maids: Your Premier Cleaning Company. We provide exceptional
                cleaning services tailored to your needs, ensuring spotless and
                immaculate spaces. From residential to commercial, our expert
                team delivers top-quality results with a commitment to detail,
                reliability, and customer satisfaction. Let us bring the shine
                back to your life!
              </p>

              <button
                onClick={() => navigate("/about-us")}
                class="btn btn-primary"
              >
                Learn More
              </button>
            </div>
          </div>
          <div class="col-lg-6">
            <img
              src="https://cdn.shopify.com/s/files/1/1845/2555/files/AspenClean_OurStory.webp?v=1683759053&width=535"
              class="img-fluid"
              alt="Company Image"
            />
          </div>
        </div>
      </section>
    </div>
  );
}
