// auth flow
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const REMOVE_CURRENT_USER = "REMOVE_CURRENT_USER";
export const SET_BOOKINGS = "SET_BOOKINGS";
export const SET_NEW_PASSWORD = "SET_NEW_PASSWORD";
export const SET_PROFILE = "SET_PROFILE";
export const SET_TOKEN_EXPIRED = "SET_TOKEN_EXPIRED";
export const UPDATE_PROFILE_PHOTO = "UPDATE_PROFILE_PHOTO";
export const SET_ALL_USERS = "SET_ALL_USERS";

export const SET_SERVICES = "SET_SERVICES";
export const SET_BLOGS = "SET_BLOGS";
