import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./services.scss";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export default function () {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <div className="services-hero">
      <Row style={{ paddingBottom: "10px", paddingTop: "20px" }}>
        <Col md={6} xs={12} className="text-left">
          <h3>Cleaning Service Vancouver, Calgary, and Toronto</h3>
          <span>
            Treat yourself and your home to a top-quality cleaning services
            Vancouver, Toronto cleaning Services or Calgary Maid and Cleaning
            Services. Born out of a vision to create toxin-free residential
            cleaning services, Marvel Maids Cleaning delivers a professional-grade
            house cleaning in the Toronto, Vancouver, and Calgary areas without
            bringing any harmful chemicals into your home. Our trained teams use
            Marvel Maids Cleaning natural cleaning products and microfiber cloths
            exclusively, giving your house an all-natural shine that you can
            trust. Learn more why you should use Marvel Maids Cleaning cleaning
            services.
          </span>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate("/online-booking");
            }}
            className="d-block button-global-booking"
          >
            BOOK NOW
          </button>
        </Col>

        <Col
          style={{
            marginTop: isMobile ? "20px" : "0px",
          }}
          md={6}
          xs={12}
          className="right-img"
        >
          <Image src="assets/images/services.jpg" className="hero-image" />
        </Col>
      </Row>
    </div>
  );
}
