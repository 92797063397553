import React, { useEffect, useCallback } from "react";
import Header from "../components/dashboard-content/Header";
import NavBar from "../components/dashboard-content//Navbar";
import { logOutUser } from "../redux/actions/auth";
import { useNavigate } from "react-router-dom";
// import { getAllUsers } from '../redux/actions/auth';
import { useDispatch, useSelector } from "react-redux";
const Layout = ({ children, header = "", sideBtn = null, hideNav = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const onLogOut = () => {
    dispatch(logOutUser());
    navigate("/login", { replace: true });
  };

  return (
    <div class="container-scroller">
      {/* <Header header={header} sideBtn={sideBtn} style={{ fontSize: "15px" }} /> */}
      <div
        class="container-fluid page-body-wrapper"
        style={hideNav ? { padding: 0 } : {}}
      >
        {!hideNav && <NavBar onLogOut={onLogOut} user={user} />}
        <div class="main-panel" style={hideNav ? { width: "100%" } : {}}>
          <div class="content-wrapper" style={{ paddingTop: 0 }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
