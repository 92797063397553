import publicReq, { privateReq, imageUpload } from "./axiosConfig";

export const AddNewBlogService = (data) =>
  privateReq.post("/blog/add-blog", data);
export const getBlogs = (data = "") => privateReq.get("/blog/blog-list" + data);
export const getBlogByIdService = (slug) =>
  publicReq.get("/blog/get-blog/" + slug);
export const updateBlogService = (data) =>
  imageUpload.post("/update-blog", data);
export const deleteBlogService = (data) =>
  privateReq.delete("/delete-blog/" + data.id);
