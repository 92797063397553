import React, { useEffect, useState } from "react";
import ServiceSingleHero from "../../components/sections/service/ServiceHero";
import ServiceOffered from "../../components/sections/service/Offer";
import ServiceBanner from "../../components/sections/service/ServiceBanner";
import { useParams } from "react-router-dom";
import { getServiceBySlugService } from "../../config/service";

const Service = () => {
  const [service, setService] = useState({});
  const params = useParams();
  const getService = async () => {
    try {
      const res = await getServiceBySlugService(params.slug);
      if (res.data.service) {
        setService(res.data.service);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getService();
  }, []);
  return (
    <>
      <ServiceBanner service={service} />
      <ServiceSingleHero service={service} />
      <ServiceOffered />
    </>
  );
};

export default Service;
