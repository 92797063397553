import React, { useEffect, useState } from "react";
import Layout from "../../../layout";
import { Link } from "react-router-dom";
import { getBlogs, deleteBlogService } from "../../../config/blog";
import AGProModal from "../../../components/Modal";
import AddBlog from "./Add";
import { assetsURL } from "../../../config/axiosConfig";
const BlogsListAdmin = () => {
  const [blogs, setBlogs] = useState([]);
  const [search, setSearch] = useState("");

  const [month, setmonth] = useState(new Date().getMonth() + 1);
  const [year, setyear] = useState(new Date().getFullYear());

  const [totalBlogs, setTotalBlogs] = useState(0);
  const [page, setPage] = useState(1);
  const [activeItem, setActiveItem] = useState({});

  const [closeModal, setCloseModal] = useState(false);

  useEffect(() => {
    onGetAllBlogs();
  }, [page]);

  const onDeleteBlog = async (id) => {
    try {
      let res = await deleteBlogService({ id });
      onGetAllBlogs();
    } catch (e) {
      console.log(e);
    }
  };

  const onGetAllBlogs = async (filterQuery = `?page=${page}`) => {
    try {
      let res = await getBlogs(filterQuery);
      setBlogs(res.data);
      setTotalBlogs(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  const filterData = (e) => {
    e.preventDefault();
    let filterQuery = `?search=${search}&month=${month}&year=${year}`;
    setPage(1);
    onGetAllBlogs(filterQuery);
  };

  const yearList = () => {
    let list = [];
    for (let i = 2010; i <= 2050; i++) {
      list.push(i);
    }
    return list;
  };

  const renderBlogs = (item, key) => {
    return (
      <tr>
        <td class="text-capitalize">{key + 1}</td>
        <td class="text-capitalize">{item.title}</td>
        <td class="text-capitalize">{item.category}</td>
        <td class="text-capitalize">
          <img src={assetsURL + item.image} height={200} width={200} />
        </td>
        <td>
          <a
            style={{ width: "20px", marginRight: "25px" }}
            href="/"
            onClick={(e) => {
              e.preventDefault();
              setActiveItem(item);
              setCloseModal(true);
            }}
          >
            {" "}
            <i
              class="menu-icon mdi mdi-pencil"
              style={{ cursor: "pointer" }}
            ></i>
          </a>
          <a
            style={{ width: "35px" }}
            href="/"
            onClick={(e) => {
              e.preventDefault();
              if (window.confirm("Are you sure want to delete?")) {
                onDeleteBlog(item._id);
              }
            }}
          >
            {" "}
            <i
              class="menu-icon mdi mdi-delete"
              style={{ cursor: "pointer" }}
            ></i>
          </a>
        </td>
      </tr>
    );
  };
  return (
    <Layout
      header="Blogs"
      sideBtn={
        <Link to="/add/blog" class="add btn btn-primary todo-list-add-btn">
          Add Blog +
        </Link>
      }
    >
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            {/* <div className="row">
                            <div className="col">
                                <h4>Filter</h4>
                            </div>
                        </div> */}
            <form onSubmit={filterData}>
              <div className="row">
                <div className="col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputUsername1"
                      placeholder="Find By Title"
                      value={search}
                      name="search"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="row  btn-section">
                    <div class="col-md-6 col-12 px-1">
                      <button type="submit" class="btn btn-primary filter-btn">
                        <i class="mdi mdi-filter"></i> <span>Filter</span>
                      </button>
                    </div>
                    <div class="col-md-6 col-12 px-1">
                      <button
                        type="button"
                        class="btn btn-primary filter-btn reset-btn"
                        onClick={() => {
                          setSearch("");

                          onGetAllBlogs();
                        }}
                      >
                        <i class="mdi mdi-close"></i> <span>Clear</span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                                    <div className="col-md-2 col-12">
                                        <div class="form-group">
                                            <select
                                                class="form-control js-example-basic-single w-100"
                                                name="sectorNumber"
                                                onChange={(e) => {
                                                    setmonth(e.target.value);
                                                }}
                                            >
                                                <option value="">Select Month</option>

                                                {[
                                                    { value: "01", text: "January" },
                                                    { value: "02", text: "February" },
                                                    { value: "03", text: "March" },
                                                    { value: "04", text: "April" },
                                                    { value: "05", text: "May" },
                                                    { value: "06", text: "June" },
                                                    { value: "07", text: "July" },
                                                    { value: "08", text: "August" },
                                                    { value: "09", text: "September" },
                                                    { value: "10", text: "October" },
                                                    { value: "11", text: "November" },
                                                    { value: "12", text: "December" },
                                                ].map((i) => {
                                                    return (
                                                        <option value={i.value} selected={month == i.value}>
                                                            {i.text}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-12">
                                        <div class="form-group">
                                            <select
                                                class="form-control js-example-basic-single w-100"
                                                name="sectorNumber"
                                                onChange={(e) => {
                                                    setyear(e.target.value);
                                                }}
                                            >
                                                <option value="">Select Year</option>

                                                {yearList().map((i) => {
                                                    return (
                                                        <option value={i} selected={year == i}>
                                                            {i}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                   
                                </div> */}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div className="row">
              <div className="col-md-6">
                <h4 class="card-title text-start">Blog List</h4>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Title</th>
                    <th>Category</th>
                    <th>Image</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {blogs &&
                    blogs.length > 0 &&
                    blogs.map((i, key) => {
                      return renderBlogs(i, key);
                    })}
                </tbody>
              </table>
              {/* {totalBlogs > 20 && (
                <div>
                  <ul className="pagination">
                    {page > 1 ? (
                      <li>
                        <a onClick={() => setPage((page) => page - 1)}>Prev</a>
                      </li>
                    ) : null}
                    <li>
                      <a onClick={() => setPage(page)}>{page}</a>
                    </li>
                    {page * 20 < totalBlogs ? (
                      <li>
                        <a onClick={() => setPage((page) => page + 1)}>
                          {page + 1}
                        </a>
                      </li>
                    ) : null}
                    {(page + 2) * 20 < totalBlogs ? (
                      <li>
                        <a onClick={() => setPage((page) => page + 2)}>
                          {page + 2}
                        </a>
                      </li>
                    ) : null}
                    {(page + 3) * 20 < totalBlogs ? (
                      <li>
                        <a onClick={() => setPage((page) => page + 3)}>
                          {page + 3}
                        </a>
                      </li>
                    ) : null}
                    {page * 20 < totalBlogs ? (
                      <li>
                        <a onClick={() => setPage((page) => page + 1)}>Next</a>
                      </li>
                    ) : null}
                  </ul>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>

      {Object.keys(activeItem).length > 0 && (
        <AGProModal
          state={closeModal}
          stateFunc={setCloseModal}
          title="Edit Blog"
        >
          <AddBlog
            edit={true}
            activeItem={activeItem}
            setCloseModal={setCloseModal}
            onGetAllBlogs={onGetAllBlogs}
          />
        </AGProModal>
      )}
    </Layout>
  );
};

export default BlogsListAdmin;
