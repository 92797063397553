import React from "react";
import './HelpsUs.css'
export default function HelpsUs() {
    return (
        <div>
            <section class="newsletter">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="content">
                                <form>
                                    <h2>SUBSCRIBE TO OUR NEWSLETTER</h2>
                                    <div class="input-group">
                                        <input type="email" class="form-control" placeholder="Enter your email" />
                                        <span class="input-group-btn">
                                            <button class="btn" type="submit">Subscribe Now</button>
                                        </span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}