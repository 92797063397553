import React from "react";
import { Link } from "react-router-dom";

const NavBar = ({ onLogOut, user }) => {
  return (
    <div className="dashboard-styles">
      <nav
        class="sidebar sidebar-offcanvas  bg-white"
        style={{ borderRight: "1px solid rgba(51, 48, 60, 0.12)" }}
        id="sidebar"
      >
        <ul class="nav">
          <li class="nav-item">
            <Link class="nav-link" to="/admin">
              <i class="mdi mdi-label-variant-outline menu-icon"></i>
              <span class="menu-title">Dashboard</span>
            </Link>
          </li>

          <>
            <li class="nav-item nav-category">General Settings</li>

            <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="collapse"
                href="#blog-management"
                aria-expanded="false"
                aria-controls="blog-management"
              >
                <i class="menu-icon mdi mdi-blogger"></i>
                <span class="menu-title">Blogs</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" id="blog-management">
                <ul class="nav flex-column sub-menu">
                  <li class="nav-item d-flex align-items-center">
                    <i
                      class="menu-icon mdi mdi-plus"
                      style={{ fontSize: "20px" }}
                    ></i>
                    <Link class="nav-link" to="/add/blog">
                      Add Blog
                    </Link>
                  </li>
                  <li class="nav-item d-flex align-items-center">
                    <i
                      class="menu-icon mdi mdi-view-list"
                      style={{ fontSize: "20px" }}
                    ></i>
                    <Link class="nav-link" to="/blogs-list/admin">
                      List
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                // data-bs-toggle="collapse"
                to="/bookings"
                // aria-expanded="false"
                // aria-controls="service-management"
              >
                <i class="menu-icon mdi mdi-currency-usd"></i>
                <span class="menu-title">Bookings</span>
                <i class="menu-arrow"></i>
              </Link>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="collapse"
                href="#service-management"
                aria-expanded="false"
                aria-controls="service-management"
              >
                <i class="menu-icon mdi mdi-settings"></i>
                <span class="menu-title">Services</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" id="service-management">
                <ul class="nav flex-column sub-menu">
                  <li class="nav-item d-flex align-items-center">
                    <i
                      class="menu-icon mdi mdi-plus"
                      style={{ fontSize: "20px" }}
                    ></i>
                    <Link class="nav-link" to="/add/service">
                      Add Service
                    </Link>
                  </li>
                  <li class="nav-item d-flex align-items-center">
                    <i
                      class="menu-icon 
                mdi mdi-view-list"
                      style={{ fontSize: "20px" }}
                    ></i>
                    <Link class="nav-link" to="/service-list/admin">
                      List
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item">
              <Link to={"/admin/add-admin"} className="nav-link">
                <i
                  class="menu-icon mdi mdi-plus"
                  style={{ fontSize: "20px" }}
                ></i>
                <span class="menu-title">Add admin</span>
              </Link>
            </li>
          </>
        </ul>
      </nav>
    </div>
  );
};
export default NavBar;
