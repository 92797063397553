import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./HomeHero.scss";
import { Carousel } from "react-bootstrap";
// import { Carousel } from "react-responsive-carousel";
import Banner2 from "../../../../assets/images/banner-2.png";
import Banner3 from "../../../../assets/images/banner-3.jpg";
import Banner4 from "../../../../assets/images/banner-4.jpg";

export default function HomeHero() {
  const [navBarHeight, setNavBarHeight] = React.useState();

  useEffect(() => {
    let height =
      document.getElementById("navbar-menu") &&
      document.getElementById("navbar-menu").offsetHeight;
    if (height) {
      setNavBarHeight(height);
    }
  }, []);
  return (
    <div className="home-hero-wrapper">
      {navBarHeight !== undefined && (
        <Carousel>
          <Carousel.Item>
            <img
              style={{
                height: `calc(100vh - ${navBarHeight}px)`,
                objectFit: "cover",
              }}
              src={Banner2}
            />
            <Carousel.Caption>
              <div class="container">
                <h1>
                  Professional Cleaners<br></br>
                  <b>Natural Cleaning Products</b>
                </h1>
                <p>
                  We have been serving for more than 10 years, that's why our
                  cleaning company are trusted by thousands of homeowners.
                </p>
                <Link to="/online-booking" class="btn btn-home-hero">
                  BOOK NOW
                </Link>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              style={{
                height: `calc(100vh - ${navBarHeight}px)`,
                objectFit: "cover",
              }}
              src={Banner3}
            />
            <Carousel.Caption>
              <div class="container">
                <h1>
                  Cleaning Services<br></br>
                  <b>You Can Trust</b>
                </h1>
                <p>
                  We make cleaning easy and affordable, to help you save time
                  and money, while keeping your space always sparky clean.
                </p>
                <Link to="/online-booking" class="btn btn-home-hero">
                  BOOK NOW
                </Link>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              style={{
                height: `calc(100vh - ${navBarHeight}px)`,
                objectFit: "cover",
              }}
              src={Banner4}
            />
            <Carousel.Caption>
              <div class="container">
                <h1>
                  Housekeeping Services<br></br>
                  <b>Reliable Cleaners</b>
                </h1>
                <p>
                  Only fully trained, bonded and insured cleaners are permitted
                  to work in our client's houses. We protect homeowners.
                </p>
                <Link to="/online-booking" class="btn btn-home-hero">
                  BOOK NOW
                </Link>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      )}

      {/* <section class="hero-section"></section> */}
    </div>
  );
}
