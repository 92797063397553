import React, { useId, useState } from "react";
import {
  Tab,
  Col,
  Nav,
  Row,
  Container,
  InputGroup,
  Form,
  Button,
  FloatingLabel,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import "./booking.scss";
// import DateTimePicker from "react-datetime-picker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import "react-datetime-picker/dist/DateTimePicker.css";
// import "react-calendar/dist/Calendar.css";
// import "react-clock/dist/Clock.css";
import { useNavigate, useLocation } from "react-router-dom";
import FridgeIcon from "../../images/booking/fridge.png";
import WashingMachIcon from "../../images/booking/washing-machine.png";
import MicroWaveIcon from "../../images/booking/microwave.png";
import DishWashIcon from "../../images/booking/dish-washer.png";
import SprayIcon from "../../images/booking/spray.png";
import PetsIcon from "../../images/booking/pets.png";
import { createSessionService } from "../../config/booking";
// import { loadStripe } from '@stripe/stripe-js';
// import CheckOut from '../../components/checkout'
// import {
//     Elements
// } from "@stripe/react-stripe-js";

// const stripePromise = loadStripe

//     ('pk_test_51KoK1JSGR8oc6lb8E5trhIMYbDgx9ksKzdAxUsSpsnbDcjWGmsTA2IO1EmLpE1iO7fCP0qQ3c9rtrju4w5P8dZw200hmPEcGUH');

const additionalAreas = [
  {
    label: "Bonus Room",
    value: "bonus-room",
    price: 30,
  },
  {
    label: "Stairs (up to 45)",
    value: "stairs",
    price: 80,
  },
  {
    label: "Hallway",
    value: "hallway",
    price: 25,
  },
  {
    label: "Dining Area",
    value: "dining-area",
    price: 45,
  },
  {
    label: "Den",
    value: "den",
    price: 30,
  },
  {
    label: "Recreation Room",
    value: "recreation-room",
    price: 70,
  },
  {
    label: "Walk-In Closet",
    value: "walk-in-closet",
    price: 20,
  },
];
const PetsTooltip = (
  <Tooltip id="pets-info">Heavy Shedding Pets $50.00</Tooltip>
);
const SpotCleaningTooltip = (
  <Tooltip id="spot-clean-info">Spot Cleaning Baseboard $75.00</Tooltip>
);
const OnlineBooking = () => {
  const naviagte = useNavigate();
  const location = useLocation();
  const [date, setDate] = useState(new Date());
  const [appliances, setApplicances] = useState([]);
  const [addOns, setAddOns] = useState([]);
  const [carpetCleaning, setCarpetCleaning] = useState(undefined);
  const [addAreas, setAddAreas] = useState([]);
  const [city, setCity] = useState("");
  const [sqFt, setSqFt] = useState("");
  const [notes, setNotes] = useState("");
  const [user, setUser] = useState({});
  const [carpetedBedrooms, setCarpetedBedrooms] = useState("");
  const [carpetedLivingRooms, setCarpetedLivingRooms] = useState("");
  const [loader, setLoader] = useState(false);
  const uid = useId();

  const handleSelect = (e, key) => {
    e.preventDefault();
    let arr = [...appliances];
    if (arr.includes(key)) {
      arr = arr.filter((item) => item !== key);
    } else {
      arr.push(key);
    }
    setApplicances(arr);
  };

  const handleUserChange = (name, value) => {
    setUser({
      ...user,
      [name]: value,
    });
  };
  const handleAddOnSelect = (e, key) => {
    e.preventDefault();
    let arr = [...addOns];
    if (arr.includes(key)) {
      arr = arr.filter((item) => item !== key);
    } else {
      arr.push(key);
    }
    setAddOns(arr);
  };
  const handleAddAreaSelect = (e, key) => {
    e.preventDefault();
    let arr = [...addAreas];
    if (arr.filter((added) => added.value === key.value).length > 0) {
      arr = arr.filter((item) => item.value !== key.value);
    } else {
      arr.push(key);
    }
    setAddAreas(arr);
  };

  const getSqFtPrice = () => {
    let total = 0;
    if (sqFt === "1") {
      total = 280;
    } else if (+sqFt > 1) {
      total = 280 + 70 * (+sqFt - 1);
    }
    return total;
  };

  const calculatePrice = (e) => {
    // e.preventDefault();
    let appliancesCost = appliances.length * 30;
    let addOnsCost = 0;
    addOns.forEach((item) => {
      if (item === "spot-clean") {
        addOnsCost += 75;
      } else if (item === "pets") {
        addOnsCost += 50;
      }
    });
    let carpetBedRoomCost = 0;
    let carpetLivingRoomCost = 0;
    let addAreasCost = 0;

    if (carpetCleaning) {
      if (carpetedBedrooms === "1") {
        carpetBedRoomCost = 80;
      } else if (+carpetedBedrooms > 1) {
        carpetBedRoomCost = 80 + 20 * (+carpetedBedrooms - 1);
      }
      if (carpetedLivingRooms === "1") {
        carpetLivingRoomCost = 50;
      } else if (+carpetedLivingRooms > 1) {
        carpetLivingRoomCost = 50 + 10 * (+carpetedLivingRooms - 1);
      }
      addAreas.forEach((item) => {
        addAreasCost += item.price;
      });
    }
    let total =
      getSqFtPrice() +
      appliancesCost +
      addOnsCost +
      carpetBedRoomCost +
      carpetLivingRoomCost +
      addAreasCost;
    return total;
  };

  const createSession = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      const data = {
        price: calculatePrice(),
        email: user.email,
        phone: user.mobile,
        address: user.address,
        uid: uid,
        booking: {
          date,
          appliances,
          addOns,
          carpetCleaning,
          addAreas,
          city,
          price: calculatePrice(),
          sqFt,
          notes,
          carpetedBedrooms,
          carpetedLivingRooms,
        },
      };
      const res = await createSessionService(data);
      if (res.data.status) {
        sessionStorage.setItem("token", res.data.token);
        setLoader(false);
        window.location.href = res.data.url;
      }
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  return (
    <>
      <Container className="booking-wrapper">
        <h2 className="heading">Book an Marvel Maids Cleaning Service</h2>
        <Row>
          <Col>
            <Form>
              <p className="text-start">Schedule Your One Time Cleaning</p>

              <p className="text-start pt-3 pb-0 mb-0">
                How many square feet is your home (including basement if we are
                cleaning it) *
              </p>
              <p className="text-start pb-0 mb-3">
                Choose your home size or select hourly for partial cleanings,
                large, builders and extra dirty homes. For move in/out please
                make sure to select the option in the extras section. If square
                footage exceeds parameters, you may be subject to additional
                charges.
              </p>
              <Form.Group
                className="mb-2 text-start"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Sqaure feet</Form.Label>

                <Form.Select
                  className="select-schdule"
                  aria-label="Default select example"
                  value={sqFt}
                  onChange={(e) => setSqFt(e.target.value)}
                >
                  <option>Please select</option>
                  {/* $280 */}
                  <option value="1">0-1000</option>
                  {/* $70 for every */}
                  <option value="2">1000-1500</option>
                  <option value="3">1500-2000</option>

                  <option value="4">2000-2500</option>
                  <option value="5">2500-3000</option>

                  <option value="6">3000-3500</option>
                  <option value="7">3500-4000</option>

                  <option value="8">4000-4500</option>
                  <option value="9">4500-5000</option>

                  <option value="10">5000-5500</option>
                  <option value="11">5500-6000</option>

                  <option value="12">6000-6500</option>
                  <option value="13">6500-7000</option>

                  <option value="14">7000-7500</option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="text-start mb-2">
                <Form.Label className="text-start">Add Ons</Form.Label>
                <p className="text-start pb-0 mb-3">
                  Select the options you would like to add to your booking.
                  <br></br>We HIGHLY recommend a deep cleaning for homes not
                  cleaned for 30 days and for first visits to get your home
                  prepared for onoging maintenance service.<br></br>For all move
                  out/in jobs, homes must be empty prior to teams arrival.
                </p>
                <Form.Group className="text-start mb-2">
                  <Form.Label className="text-start">
                    Appliances that need to be cleaned (surface & inside, not
                    underneath)
                  </Form.Label>
                </Form.Group>
                <Row className="mb-2">
                  <Col md={1} xs={3}>
                    <button
                      style={{
                        backgroundColor: "white",
                        color: "#000",
                      }}
                      onClick={(e) => handleSelect(e, "fridge")}
                      className={`border rounded p-2 ${appliances.includes("fridge") && "border-success"
                        }`}
                    >
                      <img
                        src={FridgeIcon}
                        style={{
                          width: "50%",
                          height: "50%",
                          objectFit: "contain",
                        }}
                      />
                    </button>
                  </Col>
                  <Col md={1} xs={3}>
                    <button
                      style={{
                        backgroundColor: "white",
                        color: "#000",
                      }}
                      onClick={(e) => handleSelect(e, "dish-wash")}
                      className={`border rounded p-2 ${appliances.includes("dish-wash") && "border-success"
                        }`}
                    >
                      <img
                        src={DishWashIcon}
                        style={{
                          width: "50%",
                          height: "50%",
                          objectFit: "contain",
                        }}
                      />
                    </button>
                  </Col>
                  <Col md={1} xs={3}>
                    <button
                      style={{
                        backgroundColor: "white",
                        color: "#000",
                      }}
                      onClick={(e) => handleSelect(e, "washing")}
                      className={`border rounded p-2 ${appliances.includes("washing") && "border-success"
                        }`}
                    >
                      <img
                        src={WashingMachIcon}
                        style={{
                          width: "50%",
                          height: "50%",
                          objectFit: "contain",
                        }}
                      />
                    </button>
                  </Col>
                  <Col md={1} xs={3}>
                    <button
                      style={{
                        backgroundColor: "white",
                        color: "#000",
                      }}
                      onClick={(e) => handleSelect(e, "microwave")}
                      className={`border rounded p-2 ${appliances.includes("microwave") && "border-success"
                        }`}
                    >
                      <img
                        src={MicroWaveIcon}
                        style={{
                          width: "50%",
                          height: "50%",
                          objectFit: "contain",
                        }}
                      />
                    </button>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={1} xs={3}>
                    <OverlayTrigger
                      placement="top"
                      overlay={SpotCleaningTooltip}
                    >
                      <button
                        style={{
                          backgroundColor: "white",
                          color: "#000",
                        }}
                        onClick={(e) => handleAddOnSelect(e, "spot-clean")}
                        className={`border rounded p-2 ${addOns.includes("spot-clean") && "border-success"
                          }`}
                      >
                        <img
                          src={SprayIcon}
                          style={{
                            width: "50%",
                            height: "50%",
                            objectFit: "contain",
                          }}
                        />
                      </button>
                    </OverlayTrigger>
                  </Col>
                  <Col md={1} xs={3}>
                    <OverlayTrigger placement="top" overlay={PetsTooltip}>
                      <button
                        style={{
                          backgroundColor: "white",
                          color: "#000",
                        }}
                        onClick={(e) => handleAddOnSelect(e, "pets")}
                        className={`border rounded p-2 ${addOns.includes("pets") && "border-success"
                          }`}
                      >
                        <img
                          src={PetsIcon}
                          style={{
                            width: "50%",
                            height: "50%",
                            objectFit: "contain",
                          }}
                        />
                      </button>
                    </OverlayTrigger>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="text-start mb-2">
                <Form.Label className="text-start">
                  Steam & Shampoo cleaning of carpets
                </Form.Label>

                <Row className="mb-2">
                  <Col md={1} xs={3}>
                    <button
                      style={{
                        backgroundColor: "white",
                        color: "#000",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setCarpetCleaning(false);
                      }}
                      className={`w-100 border rounded p-2 ${carpetCleaning !== undefined &&
                        !carpetCleaning &&
                        "border-success"
                        }`}
                    >
                      No
                    </button>
                  </Col>
                  <Col md={1} xs={3}>
                    <button
                      style={{
                        backgroundColor: "white",
                        color: "#000",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setCarpetCleaning(true);
                      }}
                      className={`w-100 border rounded p-2 ${carpetCleaning && "border-success"
                        }`}
                    >
                      Yes
                    </button>
                  </Col>
                </Row>
              </Form.Group>
              {carpetCleaning && (
                <Row className="mb-2">
                  <Col md={6} xs={12}>
                    <Form.Group
                      className="text-start"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>How many carpeted bedroom</Form.Label>
                      <p className="text-start pb-0 mb-0">
                        (Maximum room size is 200 sq. ft.)
                      </p>
                      <Form.Select
                        value={carpetedBedrooms}
                        onChange={(e) => setCarpetedBedrooms(e.target.value)}
                        className="select-schdule mt-1"
                      >
                        <option>Please select</option>
                        {/* $80 */}
                        <option value="1">1</option>
                        {/* $20 for every */}
                        <option value="2">2</option>
                        <option value="3">3</option>

                        <option value="4">4</option>
                        <option value="5">5</option>

                        <option value="6">6</option>
                        <option value="7">7</option>

                        <option value="8">8</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6} xs={12}>
                    <Form.Group
                      className="text-start"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>How many carpeted Living Rooms</Form.Label>
                      <p className="text-start pb-0 mb-0">
                        (Maximum room size is 200 sq. ft.)
                      </p>
                      <Form.Select
                        value={carpetedLivingRooms}
                        onChange={(e) => setCarpetedLivingRooms(e.target.value)}
                        className="mt-1 select-schdule"
                      >
                        <option>Please select</option>
                        {/* $50 */}
                        <option value="1">1</option>
                        {/* $10 for every */}
                        <option value="2">2</option>
                        <option value="3">3</option>

                        <option value="4">4</option>
                        <option value="5">5</option>

                        <option value="6">6</option>
                        <option value="7">7</option>

                        <option value="8">8</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col className="mt-2">
                    <Form.Group
                      className="text-start"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Additional Carpeted Areas</Form.Label>
                      <Row>
                        {additionalAreas.map((item) => (
                          <Col md={2} xs={6}>
                            <button
                              style={{
                                backgroundColor: "white",
                                color: "#000",
                              }}
                              onClick={(e) =>
                                handleAddAreaSelect(e, {
                                  value: item.value,
                                  price: item.price,
                                })
                              }
                              className={`w-100 mt-2 border rounded p-2 ${addAreas.filter(
                                (added) => added.value === item.value
                              ).length > 0 && "border-success"
                                }`}
                            >
                              {item.label}
                              {"  "}+${item.price}
                            </button>
                          </Col>
                        ))}
                      </Row>
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <Form.Group
                className="mb-2 text-start"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Notes for cleaning Crew</Form.Label>
                <FloatingLabel controlId="floatingTextarea43">
                  <Form.Control
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    as="textarea"
                    className="select-schdule"
                    style={{ height: "100px" }}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group
                className="text-start mt-4"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Your details</Form.Label>
                <p className="text-start">
                  This information will be used to contact you about your
                  service
                </p>
                <Row>
                  <Col md={6} xs={12}>
                    <p className="text-start pb-0 mb-2 mt-2">Email</p>
                    <Form.Control
                      aria-label="Email"
                      type="email"
                      value={user.email}
                      onChange={(e) =>
                        handleUserChange("email", e.target.value)
                      }
                      aria-describedby="basic-addon1"
                      className="select-schdule"
                    />
                  </Col>
                  <Col md={6} xs={12}>
                    <p className="text-start pb-0 mb-2 mt-2">Contact No.</p>
                    <Form.Control
                      aria-label="Contact No."
                      type="number"
                      value={user.mobile}
                      onChange={(e) =>
                        handleUserChange("mobile", e.target.value)
                      }
                      aria-describedby="basic-addon1"
                      className="select-schdule"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} xs={12}>
                    <p className="text-start pb-0 mb-2 mt-2">Address</p>
                    <Form.Control
                      aria-label="Address"
                      aria-describedby="basic-addon1"
                      className="select-schdule"
                      value={user.address}
                      onChange={(e) =>
                        handleUserChange("address", e.target.value)
                      }
                    />
                  </Col>
                  <Col md={6} xs={12}>
                    <p className="text-start pb-0 mb-2 mt-2">City</p>
                    <InputGroup className="mb-3 ">
                      <Form.Control
                        aria-label="City"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        aria-describedby="basic-addon1"
                        className="select-schdule"
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group
                className="mb-2 text-start"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="schdule-heading mb-2 pb-0">
                  Date And Arrival Time Of Your Appointment
                </Form.Label>
                {/* <DateTimePicker
                    value={date}
                    onChange={setDate}
                    minDate={new Date()}
                    className={"w-100"}
                    tileClassName={"w-100"}
                    clockClassName={"w-100"}
                  /> */}
                <div className="react-calendar">
                  <DatePicker
                    showIcon
                    minDate={new Date()}
                    selected={date}
                    onChange={(date) => setDate(date)}
                    showTimeSelect
                    dateFormat="Pp"
                  />
                </div>
              </Form.Group>
              {/* <Form.Group
                className="mb-2 text-start"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Parking
                  <i
                    class="fa fa-question-circle info-icon"
                    aria-hidden="true"
                  ></i>
                </Form.Label>

                <Form.Select
                  className="select-schdule"
                  aria-label="Default select example"
                >
                  <option value="driveway">Driveway</option>
                  <option value="free street parking">
                    Free Street Parking
                  </option>
                  <option value="paid parking(charges apply)">
                    Paid Parking(Charges Apply)
                  </option>

                  <option value="other, please specify">
                    Other, Please Specify
                  </option>
                </Form.Select>
              </Form.Group>
              <Form.Group
                className="mb-2 text-start"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Garbage Disposal</Form.Label>
                <Form.Select
                  className="select-schdule"
                  aria-label="Default select example"
                >
                  <option value="bin in the garage">Bin in the Garage</option>
                  <option value="dumpster in the back of the house">
                    Dumpster in the back of the house
                  </option>
                  <option value="dumpster in the front of the house">
                    dumpster in the front of the house
                  </option>

                  <option value="other, please specify">
                    Other, Please Specify
                  </option>
                </Form.Select>
              </Form.Group>

              <Form.Group
                className="mb-2 text-start"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Washer & Dryer Instructions</Form.Label>
                <FloatingLabel controlId="floatingTextarea2">
                  <Form.Control
                    as="textarea"
                    className="select-schdule"
                    style={{ height: "100px" }}
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="text-start mb-2">
                <Form.Label className="text-start">
                  How did you hear about us?
                </Form.Label>
                <InputGroup className="mb-3 ">
                  <Form.Control
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    className="select-schdule"
                  />
                </InputGroup>
              </Form.Group>
              <p className="text-start">Regular Maintainence Cleaning</p>
              <Form.Group
                className="mb-2 text-start"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Would you like to set up a schedule for Regular Cleanings
                </Form.Label>

                <Form.Select
                  className="select-schdule"
                  aria-label="Default select example"
                >
                  <option>Select...</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                  <option value="contact me after my first service">
                    Contact me after my first service
                  </option>
                </Form.Select>
              </Form.Group> */}
              <div className="mt-5">
                <p className="text-start h4">
                  <b>
                    Total cost: <b>CA$ {calculatePrice()}</b>
                  </b>
                </p>
                <p className="text-start">
                  This is an estimate amount. If there’s anything extra that’s
                  added afterwards the amount is subject to change.
                </p>
              </div>
              <div className="button-group mt-3">
                {/* <Button onClick={calculatePrice} variant="primary">
                  Next
                  <i
                    class="fa fa-arrow-circle-right"
                    aria-hidden="true"
                    style={{
                      marginLeft: "10px",
                      fontSize: "20px",
                      textAlign: "center",
                      alignSelf: "center",
                    }}
                  ></i>
                </Button>
                <Button onClick={() => {}} variant="btn-light">
                  Back
                </Button> */}
                <Button onClick={createSession} className="btn-booking">
                  {loader ? "Please wait..." : "BOOK NOW"}
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default OnlineBooking;
