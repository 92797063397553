import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./MegamenuNew.scss";
import Logo from "../assets/images/logo.jpeg";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useMediaQuery } from "react-responsive";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { logOutUser } from "../redux/actions/auth";
const blogCategories = [
  "Cleaning Tips",
  "Checklists & Schedules",
  "Sustainable Living",
  "Product How-To's",
  "Laundry Detergent How To's",
];

export default function MegaMenuNew() {
  const user = useSelector((state) => state.auth.user);
  const { blogs } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const location = useLocation();
  const onLogout = (e) => {
    e.preventDefault();
    dispatch(logOutUser());
    window.location.href = "/";
  };
  const popOver = (
    <Popover
      style={{
        zIndex: 99999,
      }}
    >
      <div className="flex-column d-flex">
        <a
          className="p-2 text-decoration-none mx-2 text-black"
          href={
            user
              ? user?.role === "super-admin"
                ? "/admin"
                : "/bookings"
              : "/login"
          }
        >
          Account
        </a>
        <a
          className="p-2 text-decoration-none mx-2"
          style={{
            color: "red",
          }}
          onClick={onLogout}
          href={"#"}
        >
          Logout
        </a>
      </div>
    </Popover>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const getBlogsOfCategory = (category) => {
    return blogs.length > 0
      ? blogs?.filter((blog) => blog.category === category)
      : [];
  };
  return (
    <>
      {!isMobile ? (
        <div class="ruby-menu-demo-header" id="navbar-menu">
          <div class="ruby-wrapper ">
            <button
              class="navbar-toggler c-hamburger c-hamburger--htx visible-xs"
              type="button"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
              aria-controls="bs-example-navbar-collapse-1"
              aria-expanded={!isNavCollapsed ? true : false}
              onClick={handleNavCollapse}
              aria-label="Toggle navigation"
            >
              <span class="mdi mdi-menu"></span>
            </button>
            {/* <button class="c-hamburger c-hamburger--htx visible-xs" type="button" onClick={(e) => { e.preventDefault(); document.getElementById("c-hamburger--htx").classList.toggle("active") }}>
                        <span>toggle menu</span>
                    </button> */}

            <ul class="ruby-menu">
              <li className="logo-img">
                <Link to="/">
                  <img src={Logo} />
                  {/* <img src="/assets/images/logo.jpeg" /> */}
                </Link>
              </li>

              <li class="ruby-active-menu-item">
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              {/* <ul class="">
                                <li><a href="#"></a></li>
                                <li><a href="#"></a></li>
                                <li><a href="#"></a> */}
              {/* <ul>
                                        <li><a href="#"><i class="fa fa-university" aria-hidden="true"></i></a>
                                            <ul>
                                                <li><a href="#"></a></li>
                                                <li><a href="#"></a></li>
                                            </ul>
                                            <span class="ruby-dropdown-toggle"></span></li>
                                        <li><a href="#"><i class="fa fa-thumbs-up" aria-hidden="true"></i>3rd Level #2</a></li>
                                        <li><a href="#"><i class="fa fa-users" aria-hidden="true"></i></a>
                                            <ul>
                                                <li><a href="#"><i class="fa fa-paper-plane" aria-hidden="true"></i></a></li>
                                                <li><a href="#"><i class="fa fa-print" aria-hidden="true"></i></a></li>
                                                <li><a href="#"><i class="fa fa-shopping-bag" aria-hidden="true"></i>
                                                </a></li>
                                            </ul>
                                            <span class="ruby-dropdown-toggle"></span></li>
                                    </ul> */}
              {/* <span class="ruby-dropdown-toggle"></span></li> */}
              {/* <li class="ruby-open-to-left"><a href="#"></a> */}
              {/* <ul>
                                    <li><a href="#"></a></li>
                                    <li><a href="#"></a></li>
                                    <li><a href="#"></a></li>
                                </ul> */}
              {/* <span class="ruby-dropdown-toggle"></span></li> */}
              {/* <li><a href="#"></a></li> */}
              {/* </ul> */}
              {/* <span class="ruby-dropdown-toggle"></span></li> */}

              <li class="ruby-menu-mega">
                <Link to="/services">Services</Link>
                <div class="ruby-grid ruby-grid-lined">
                  <div class="ruby-row">
                    {/* <div class="ruby-col-2">
                      <h3 class="ruby-list-heading">TORONTO</h3>
                      <ul>
                        <li>
                          <Link to="/services">
                            Toronto House Cleaning Services
                          </Link>
                        </li>
                        <li>
                          <Link to="/services">Woodbridge</Link>
                        </li>
                        <li>
                          <Link to="/services">Markham</Link>
                        </li>
                        <li>
                          <Link to="/services">Vaughan</Link>
                        </li>
                        <li>
                          <Link to="/services">Richmond Hill</Link>
                        </li>
                        <li>
                          <Link to="/services">Thornhill</Link>
                        </li>
                        <li>
                          <Link to="/services">Etobicoke</Link>
                        </li>
                        <li>
                          <Link to="/services">North York</Link>
                        </li>
                      </ul>
                    </div>
                    <div class="ruby-col-2 hidden-md">
                      <h3 class="ruby-list-heading">VANCOUVER</h3>
                      <ul>
                        <li>
                          <Link to="/services">
                            Vancouver House Cleaning Services
                          </Link>
                        </li>
                        <li>
                          <Link to="/services">West Vancouver</Link>
                        </li>
                        <li>
                          <Link to="/services">North Vancouver</Link>
                        </li>
                        <li>
                          <Link to="/services">Burnaby</Link>
                        </li>
                        <li>
                          <Link to="/services">New Westminster</Link>
                        </li>
                        <li>
                          <Link to="/services">Richmond</Link>
                        </li>
                        <li>
                          <Link to="/services">Coquitlam</Link>
                        </li>
                        <li>
                          <Link to="/services">
                            Port Coquitlam & Port Moody
                          </Link>
                        </li>
                      </ul>
                    </div> */}
                    <div class="ruby-col-3 ruby-col-4-md">
                      <h3 class="ruby-list-heading">CALGARY</h3>
                      <ul class="ruby-list-with-images">
                        <li>
                          <Link to="/services">
                            Calgary House Cleaning Service
                          </Link>
                        </li>
                        {/* <li><a href="#"></a><span class="ruby-list-desc"></span></li>
                                            <li><a href="#"></a><span class="ruby-list-desc"></span></li>
                                            <li><a href="#"></a><span class="ruby-list-desc"></span></li> */}
                      </ul>
                    </div>
                    <div class="ruby-col-2 hidden-md">
                      <h3 class="ruby-list-heading">SERVICES</h3>
                      <ul>
                        <li>
                          <Link to="/services">Cleaning Services</Link>
                        </li>
                        <li>
                          <Link to="/services">House Cleaning</Link>
                        </li>
                        <li>
                          <Link to="/services">Office Cleaning</Link>
                        </li>
                        <li>
                          <Link to="/services">Condo & Apartment</Link>
                        </li>
                        <li>
                          <Link to="/services">Carpet Cleaning</Link>
                        </li>
                        <li>
                          <Link to="/services">Airbnb Cleaning Services</Link>
                        </li>
                        {/* <li><a href="#"><i class="fa fa-motorcycle" aria-hidden="true"></i>Menu Item #1</a></li>
                                            <li><a href="#"><i class="fa fa-music" aria-hidden="true"></i>Menu Item #2</a></li>
                                            <li><a href="#"><i class="fa fa-shopping-cart" aria-hidden="true"></i>Menu Item #3</a></li>
                                            <li><a href="#"><i class="fa fa-sliders" aria-hidden="true"></i>Menu Item #4</a></li>
                                            <li><a href="#"><i class="fa fa-search" aria-hidden="true"></i>Menu Item #5</a></li> */}
                      </ul>
                    </div>
                    <div class="ruby-col-2 hidden-md">
                      <h3 class="ruby-list-heading">TYPES OF CLEANING</h3>
                      <ul>
                        <li>
                          <Link to="/services">Regular Cleaning</Link>
                        </li>
                        <li>
                          <Link to="/services">One-time Cleaning</Link>
                        </li>
                        <li>
                          <Link to="/services">Me Move Out / Move In</Link>
                        </li>
                        <li>
                          <Link to="/services">Post-Construction</Link>
                        </li>
                        <li>
                          <Link to="/services">Add-ons & Special Requests</Link>
                        </li>
                        {/* <li><a href="#"><i class="fa fa-motorcycle" aria-hidden="true"></i>Menu Item #1</a></li>
                                            <li><a href="#"><i class="fa fa-music" aria-hidden="true"></i>Menu Item #2</a></li>
                                            <li><a href="#"><i class="fa fa-shopping-cart" aria-hidden="true"></i>Menu Item #3</a></li>
                                            <li><a href="#"><i class="fa fa-sliders" aria-hidden="true"></i>Menu Item #4</a></li>
                                            <li><a href="#"><i class="fa fa-search" aria-hidden="true"></i>Menu Item #5</a></li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                <span class="ruby-dropdown-toggle"></span>
              </li>

              <li class="ruby-menu-mega-blog">
                <Link to="/blogs">Blog</Link>
                <div style={{ height: "269.359px" }} class="">
                  <ul class="ruby-menu-mega-blog-nav">
                    {blogCategories?.map((category) => (
                      <li>
                        <a href="#">{category}</a>
                        <div
                          class="ruby-grid ruby-grid-lined"
                          style={{ height: "264.359px" }}
                        >
                          <div class="ruby-row">
                            {getBlogsOfCategory(category).length > 2 &&
                              getBlogsOfCategory(category)
                                .slice(0, 2)
                                .map((blog) => (
                                  <div class="ruby-col-6">
                                    <div class="ruby-col-4"></div>
                                    <div class="ruby-col-8">
                                      <span class="ruby-c-title">
                                        <a href="#">{blog.title}</a>
                                      </span>
                                      <div class="ruby-c-inline">
                                        {/* <span class="ruby-c-category">
                                      <a href="#">
                                        <i
                                          class="fa fa-tag"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        {blog.category}
                                      </a>
                                    </span> */}
                                        <span class="ruby-c-date">
                                          <a href="#">
                                            <i
                                              class="fa fa-calendar"
                                              aria-hidden="true"
                                            ></i>{" "}
                                            {moment(blog.created).format(
                                              "DD/MM/YYY"
                                            )}
                                          </a>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                          </div>
                        </div>
                        <span class="ruby-dropdown-toggle"></span>
                      </li>
                    ))}

                    {/* <li><a href="#">Blog-Article-List</a>
                                        <div class="ruby-grid ruby-grid-lined" style={{ height: '264.359px' }}>
                                            <div class="ruby-row">
                                                <div class="ruby-col-6">
                                                    <span class="ruby-c-title" style={{ marginBottom: '15px' }}>POPULAR THREADS</span>
                                                    <div class="ruby-row">
                                                        <div class="ruby-col-1">blog-</div>
                                                        <div class="ruby-col-11"><span class="ruby-c-title"><a href="#">An Erupting Volcano And A Meteor Has Created A Fantastic View</a></span></div>
                                                    </div>
                                                    <div class="ruby-row">
                                                        <div class="ruby-col-1">blog-</div>
                                                        <div class="ruby-col-11"><span class="ruby-c-title"><a href="#">Bottle Labels With Short Stories To Be Read Is The New Marketing Era</a></span></div>
                                                    </div>
                                                    <div class="ruby-row">
                                                        <div class="ruby-col-1">blog-</div>
                                                        <div class="ruby-col-11"><span class="ruby-c-title"><a href="#">10+ Stunning Animal Portraits That Has Been Filmed By Polyushko</a></span></div>
                                                    </div>
                                                    <div class="ruby-row">
                                                        <div class="ruby-col-1"></div>
                                                        <div class="ruby-col-11"><span class="ruby-c-title"><a href="#">10 Freaking Facts About Being An airline pilot</a></span></div>
                                                    </div>
                                                </div>
                                                <div class="ruby-col-6">
                                                    <span class="ruby-c-title" style={{ marginBottom: '15px' }}>MOST COMMENTED</span>
                                                    <div class="ruby-row">
                                                        <div class="ruby-col-1"></div>
                                                        <div class="ruby-col-11"><span class="ruby-c-title"><a href="#">An Erupting Volcano And A Meteor Has Created A Fantastic View</a></span></div>
                                                    </div>
                                                    <div class="ruby-row">
                                                        <div class="ruby-col-1"></div>
                                                        <div class="ruby-col-11"><span class="ruby-c-title"><a href="#">Bottle Labels With Short Stories To Be Read Is The New Marketing Era</a></span></div>
                                                    </div>
                                                    <div class="ruby-row">
                                                        <div class="ruby-col-1"></div>
                                                        <div class="ruby-col-11"><span class="ruby-c-title"><a href="#">10+ Stunning Animal Portraits That Has Been Filmed By Polyushko</a></span></div>
                                                    </div>
                                                    <div class="ruby-row">
                                                        <div class="ruby-col-1"></div>
                                                        <div class="ruby-col-11"><span class="ruby-c-title"><a href="#">10 Freaking Facts About Being An airline pilot</a></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <span class="ruby-dropdown-toggle"></span></li> */}
                  </ul>
                </div>
                <span class="ruby-dropdown-toggle"></span>
              </li>
              <li class="ruby-active-menu-item">
                <Link to="/faq">FAQs</Link>
              </li>
              {/* <li class="ruby-menu-mega-shop"><a href="#">Shop</a>
                            <div style={{ height: '263px' }} class="">
                                <ul>
                                    <li class="ruby-active-menu-item"><a href="#">Clothing</a>
                                        <div class="ruby-grid ruby-grid-lined">
                                            <div class="ruby-row">
                                                <div class="ruby-col-2">
                                                    <h3 class="ruby-list-heading">TOPS</h3>
                                                    <ul>
                                                        <li><a href="#">Menu Item #1</a></li>
                                                        <li><a href="#">Menu Item #2</a></li>
                                                        <li><a href="#">Menu Item #3</a></li>
                                                        <li><a href="#">Menu Item #4</a></li>
                                                        <li><a href="#">Menu Item #5</a></li>
                                                    </ul>
                                                </div>
                                                <div class="ruby-col-2">

                                                </div>
                                                <div class="ruby-col-2">
                                                    <h3 class="ruby-list-heading">BOTTOM</h3>
                                                    <ul>
                                                        <li><a href="#">Menu Item #1</a></li>
                                                        <li><a href="#">Menu Item #2</a></li>
                                                        <li><a href="#">Menu Item #3</a></li>
                                                        <li><a href="#">Menu Item #4</a></li>
                                                        <li><a href="#">Menu Item #5</a></li>
                                                    </ul>
                                                </div>
                                                <div class="ruby-col-2">
                                                    <h3 class="ruby-list-heading">NIGHTWEAR</h3>
                                                    <ul>
                                                        <li><a href="#">Menu Item #1</a></li>
                                                        <li><a href="#">Menu Item #2</a></li>
                                                        <li><a href="#">Menu Item #3</a></li>
                                                        <li><a href="#">Menu Item #4</a></li>
                                                        <li><a href="#">Menu Item #5</a></li>
                                                    </ul>
                                                </div>
                                                <div class="ruby-col-2">

                                                </div>
                                                <div class="ruby-col-2">
                                                    <h3 class="ruby-list-heading">SWIMWEAR</h3>
                                                    <ul>
                                                        <li><a href="#">Menu Item #1</a></li>
                                                        <li><a href="#">Menu Item #2</a></li>
                                                        <li><a href="#">Menu Item #3</a></li>
                                                        <li><a href="#">Menu Item #4</a></li>
                                                        <li><a href="#">Menu Item #5</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <span class="ruby-dropdown-toggle"></span></li>
                                    <li><a href="#">Outerwear</a>
                                        <div class="ruby-grid ruby-grid-lined">
                                            <div class="ruby-row">
                                                <div class="ruby-col-3">

                                                </div>
                                                <div class="ruby-col-3">
                                                    <h3 class="ruby-list-heading">COATS</h3>
                                                    <ul>
                                                        <li><a href="#">Menu Item #1</a></li>
                                                        <li><a href="#">Menu Item #2</a></li>
                                                        <li><a href="#">Menu Item #3</a></li>
                                                        <li><a href="#">Menu Item #4</a></li>
                                                        <li><a href="#">Menu Item #5</a></li>
                                                        <li><a href="#">Menu Item #6</a></li>
                                                        <li><a href="#">Menu Item #7</a></li>
                                                    </ul>
                                                </div>
                                                <div class="ruby-col-3">
                                                    <h3 class="ruby-list-heading">JACKETS</h3>
                                                    <ul>
                                                        <li><a href="#">Menu Item #1</a></li>
                                                        <li><a href="#">Menu Item #2</a></li>
                                                        <li><a href="#">Menu Item #3</a></li>
                                                        <li><a href="#">Menu Item #4</a></li>
                                                        <li><a href="#">Menu Item #5</a></li>
                                                        <li><a href="#">Menu Item #6</a></li>
                                                        <li><a href="#">Menu Item #7</a></li>
                                                    </ul>
                                                </div>
                                                <div class="ruby-col-3">
                                                    <h3 class="ruby-list-heading">LEATHER</h3>
                                                    <ul>
                                                        <li><a href="#">Menu Item #1</a></li>
                                                        <li><a href="#">Menu Item #2</a></li>
                                                        <li><a href="#">Menu Item #3</a></li>
                                                        <li><a href="#">Menu Item #4</a></li>
                                                        <li><a href="#">Menu Item #5</a></li>
                                                        <li><a href="#">Menu Item #6</a></li>
                                                        <li><a href="#">Menu Item #7</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <span class="ruby-dropdown-toggle"></span></li>
                                    <li><a href="#">Bags &amp; Shoes</a>
                                        <div class="ruby-grid ruby-grid-lined">
                                            <div class="ruby-row">
                                                <div class="ruby-col-3">
                                                </div>
                                                <div class="ruby-col-3">
                                                    <h3 class="ruby-list-heading">BAGS</h3>
                                                    <ul>
                                                        <li><a href="#">Menu Item #1</a></li>
                                                        <li><a href="#">Menu Item #2</a></li>
                                                        <li><a href="#">Menu Item #3</a></li>
                                                        <li><a href="#">Menu Item #4</a></li>
                                                        <li><a href="#">Menu Item #5</a></li>
                                                    </ul>
                                                </div>
                                                <div class="ruby-col-3">
                                                    <h3 class="ruby-list-heading">SHOES</h3>
                                                    <ul>
                                                        <li><a href="#">Menu Item #1</a></li>
                                                        <li><a href="#">Menu Item #2</a></li>
                                                        <li><a href="#">Menu Item #3</a></li>
                                                        <li><a href="#">Menu Item #4</a></li>
                                                        <li><a href="#">Menu Item #5</a></li>
                                                    </ul>
                                                </div>
                                                <div class="ruby-col-3">
                                                </div>
                                            </div>
                                        </div>
                                        <span class="ruby-dropdown-toggle"></span></li>
                                    <li><a href="#">Accessories</a>
                                        <div class="ruby-grid ruby-grid-lined">
                                            <div class="ruby-row">
                                                <div class="ruby-col-3">

                                                    <h3 class="ruby-list-heading" style={{ marginTop: '16px' }}>EYEWEAR</h3>
                                                    <ul>
                                                        <li><a href="#">Menu Item #1</a></li>
                                                        <li><a href="#">Menu Item #2</a></li>
                                                        <li><a href="#">Menu Item #3</a></li>
                                                        <li><a href="#">Menu Item #4</a></li>
                                                    </ul>
                                                </div>
                                                <div class="ruby-col-3">

                                                    <h3 class="ruby-list-heading" style={{ marginTop: '16px' }}>JEWELLERY</h3>
                                                    <ul>
                                                        <li><a href="#">Menu Item #1</a></li>
                                                        <li><a href="#">Menu Item #2</a></li>
                                                        <li><a href="#">Menu Item #3</a></li>
                                                        <li><a href="#">Menu Item #4</a></li>
                                                    </ul>
                                                </div>
                                                <div class="ruby-col-3">

                                                    <h3 class="ruby-list-heading" style={{ marginTop: '16px' }}>WATCHES</h3>
                                                    <ul>
                                                        <li><a href="#">Menu Item #1</a></li>
                                                        <li><a href="#">Menu Item #2</a></li>
                                                        <li><a href="#">Menu Item #3</a></li>
                                                        <li><a href="#">Menu Item #4</a></li>
                                                    </ul>
                                                </div>
                                                <div class="ruby-col-3">

                                                    <h3 class="ruby-list-heading" style={{ marginTop: '16px' }}>OTHERS</h3>
                                                    <ul>
                                                        <li><a href="#">Menu Item #1</a></li>
                                                        <li><a href="#">Menu Item #2</a></li>
                                                        <li><a href="#">Menu Item #3</a></li>
                                                        <li><a href="#">Menu Item #4</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <span class="ruby-dropdown-toggle"></span></li>
                                    <li><a href="#">Collections</a>
                                        <div class="ruby-grid ruby-grid-lined">
                                            <div class="ruby-row">
                                                <div class="ruby-col-3">

                                                </div>
                                                <div class="ruby-col-3">

                                                </div>
                                                <div class="ruby-col-3">

                                                </div>
                                                <div class="ruby-col-3">

                                                </div>
                                            </div>
                                            <div class="ruby-row">
                                                <div class="ruby-col-3">

                                                </div>
                                                <div class="ruby-col-3">

                                                </div>
                                                <div class="ruby-col-3">

                                                </div>
                                                <div class="ruby-col-3">

                                                </div>
                                            </div>
                                        </div>
                                        <span class="ruby-dropdown-toggle"></span></li>
                                </ul>
                            </div>
                            <span class="ruby-dropdown-toggle"></span></li> */}

              {/* <li class="ruby-menu-right"><a href="#"></a> */}
              {/* <ul>
                                <li><a href="#">2nd Level #1</a></li>
                                <li><a href="#">2nd Level #2</a></li>
                                <li class="ruby-open-to-left"><a href="#">2nd Level #3</a>
                                    <ul>
                                        <li class="ruby-open-to-left"><a href="#"><i class="fa fa-university" aria-hidden="true"></i>3rd Level #1</a>
                                            <ul>
                                                <li><a href="#">4th Level #1</a></li>
                                                <li><a href="#">4th Level #2</a></li>
                                            </ul>
                                            <span class="ruby-dropdown-toggle"></span></li>
                                        <li><a href="#"><i class="fa fa-thumbs-up" aria-hidden="true"></i>3rd Level #2</a></li>
                                        <li><a href="#"><i class="fa fa-users" aria-hidden="true"></i>3rd Level #3</a>
                                        </li><li><a href="#"><i class="fa fa-file" aria-hidden="true"></i>3rd Level #4</a>
                                            <ul>
                                                <li><a href="#"><i class="fa fa-paper-plane" aria-hidden="true"></i>4th Level #1</a></li>
                                                <li><a href="#"><i class="fa fa-print" aria-hidden="true"></i>4th Level #2</a></li>
                                                <li><a href="#"><i class="fa fa-shopping-bag" aria-hidden="true"></i>4th Level #3</a></li>
                                            </ul>
                                            <span class="ruby-dropdown-toggle"></span></li>
                                    </ul>
                                    <span class="ruby-dropdown-toggle"></span></li>
                                <li class="ruby-open-to-left"><a href="#">2nd Level #4</a>
                                    <ul>
                                        <li><a href="#">3rd Level #1</a></li>
                                        <li><a href="#">3rd Level #2</a></li>
                                        <li><a href="#">3rd Level #3</a></li>
                                    </ul>
                                    <span class="ruby-dropdown-toggle"></span></li>
                                <li><a href="#">2nd Level #5</a></li>
                            </ul> */}
              {/* <span class="ruby-dropdown-toggle"></span></li> */}
              <li class="ruby-menu-right ruby-menu-social">
                {user && user !== null ? (
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={popOver}
                  >
                    <a href={"#"}>
                      <i class="fa-solid fa-circle-user me-2"></i>
                      <span></span>
                    </a>
                  </OverlayTrigger>
                ) : (
                  <a href={"/login"}>
                    <i class="fa-solid fa-circle-user me-2"></i>
                    <span></span>
                  </a>
                )}
              </li>
              {/* <li class="ruby-menu-right ruby-menu-social"><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i><span></span></a></li> */}

              {/* <li class="ruby-menu-right ruby-menu-social ruby-menu-search">
              <a href="#">
                <i class="fa fa-search" aria-hidden="true"></i>
                <span>
                  <input type="text" name="search" placeholder="Search.." />
                </span>
              </a>
            </li> */}
            </ul>
          </div>
        </div>
      ) : (
        <Navbar
          id="navbar-menu"
          expand="lg"
          style={{ backgroundColor: "#000" }}
          className="bg-body-tertiary"
        >
          <Container className="p-3" fluid>
            <Navbar.Brand className="text-white" href="/">
              <img src={Logo} style={{ height: 35, width: 130 }} />
              {/* <img src="/assets/images/logo.jpeg" style={{ height: 60, width: 130 }} /> */}
            </Navbar.Brand>
            <Navbar.Toggle
              children={<span class="text-white mdi mdi-menu"></span>}
              aria-controls="navbarScroll"
            />
            <Navbar.Collapse id="navbarScroll">
              <Nav className="me-auto my-2 my-lg-0">
                <Nav.Link
                  as={Link}
                  to="/online-booking"
                  className="w-100 text-white text-start"
                >
                  Book Now
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/services"
                  className="text-white text-start"
                >
                  Services
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/blogs"
                  className="text-white text-start"
                >
                  Blogs
                </Nav.Link>
                <Nav.Link as={Link} to="/faq" className="text-white text-start">
                  FAQs
                </Nav.Link>
                {user && user !== null ? (
                  <>
                    <Nav.Link
                      as={Link}
                      to={
                        user
                          ? user?.role === "super-admin"
                            ? "/admin"
                            : "/bookings"
                          : "/login"
                      }
                      className="text-white text-start"
                    >
                      Account
                    </Nav.Link>
                    <Nav.Link
                      href="#"
                      onClick={onLogout}
                      className="text-white text-start"
                    >
                      Logout
                    </Nav.Link>
                  </>
                ) : (
                  <Nav.Link
                    as={Link}
                    to="/login"
                    className="text-white text-start"
                  >
                    Login
                  </Nav.Link>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      )}
    </>
  );
}
