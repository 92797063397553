import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./single-service.scss";
import { Link } from "react-router-dom";

export default function ServiceBanner({ service }) {
    return (
        <>
            <section class="single-service-hero-banner">
                <div class="container headingBanner">
                    <h1>{service.title}</h1>
                    <p>
                        {service.cleaningType} services where your cleaning <br />
                        teams are fully trained, your cleaning products are green,
                        <br /> and your satisfaction is guaranteed, since 2004.
                        <br></br>
                        <p className="mt-2">
                            <h5 className="d-inline bold">Category:</h5> {service.category}
                        </p>
                    </p>
                    <Link to="/online-booking" class="btn btn-primary btn-lg">
                        BOOK NOW
                    </Link>
                </div>
            </section>
        </>
    );
}