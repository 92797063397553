import React from 'react';
import HelpUs from '../../components/sections/helpus/HelpsUs'
const HelpUsScreen = () => {

    return (
        <div className='help-us-styles'>
            <HelpUs />
        </div>
    )
}
export default HelpUsScreen;