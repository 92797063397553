import publicReq, { privateReq, imageUpload } from "./axiosConfig";

export const validateUser = (data) => publicReq.post("/validate-user", data);

export const sendOtp = (data) => publicReq.get(`/send-otp?phone=${data.phone}`);
export const verifyOtp = (data) =>
  publicReq.get(`/verify-otp?otp=${data.otp}&sessionId=${data.sessionId}`);

export const getProfile = (data) => privateReq.get("/get-profile", data);
export const RegisterService = (data) => publicReq.post("/register", data);
export const LoginService = (data) => publicReq.post("/login", data);
export const updateUser = (data) => privateReq.post("/update-user", data);
export const updateUserImage = (data) =>
  imageUpload.post("/update-user-image", data);
export const ChangePasswordService = (data) =>
  privateReq.post("/change-password", data);
export const forgotPasswordService = (data) =>
  publicReq.post("/forgot-password", data);
export const RegisterOtpService = (data) =>
  privateReq.post("/user/register-otp", data);
export const getAllUsers = (data) => privateReq.get("/get-all-users" + data);
export const getUserService = (_id) => privateReq.get("/get-user/" + _id);

export const RefreshTokenService = (data) =>
  privateReq.get("/auth/token" + data);
export const contactService = (data) => publicReq.post("/contact", data);
export const verifyCaptchaService = (data) =>
  publicReq.post("/verify-captcha", data);
export const guestLoginServoce = (data) => publicReq.post("/guest-login", data);
export const verifyGuestLoginService = (data) =>
  publicReq.post("/verify", data);
export const resendGuestOtpService = (data) =>
  publicReq.post("/resend-otp", data);
export const getUsersByRoleService = (data) =>
  privateReq.get("/get-users" + data);
export const registerAdminService = (data) =>
  privateReq.post("/register-admin", data);
