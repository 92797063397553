import axios from "axios";

const development = process.env.NODE_ENV === "development";
export const assetsURL = development
  ? "http://localhost:5000/uploads/"
  : process.env.REACT_APP_ASSET_URL;
let baseURL = development
  ? "http://localhost:5000/api"
  : process.env.REACT_APP_BASE_URL;

const publicReq = axios.create({
  baseURL,
});

const privateReq = axios.create({
  baseURL,
});

const imageUpload = axios.create({
  baseURL,
});

imageUpload.defaults.headers.common["Content-Type"] = "multipart/form-data";

imageUpload.interceptors.request.use(async (config) => {
  const tokenValue = sessionStorage.getItem("token");

  const error = {};
  config.headers.Authorization = `Bearer ${tokenValue}`;

  return config;
});
privateReq.interceptors.request.use((config) => {
  const token = sessionStorage.getItem("token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export { imageUpload, privateReq };
export default publicReq;
