import React from 'react';
import AboutHero from '../../components/sections/about/hero/AboutHero';

const AboutUs = () => {

    return (
        <div>
            <AboutHero />
        </div>
    )
}

export default AboutUs;