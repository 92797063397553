import React from "react";
import ServiceHero from "../../components/sections/services/ServiceHero";
import ServiceOffered from "../../components/sections/services/Offer";
import ServicesBanner from "../../components/sections/services/ServiceBanner";

const Services = () => {

    return (
        <>
            <ServicesBanner />
            <ServiceHero />
            <ServiceOffered />
        </>

    )
}

export default Services;