import React from "react";
import "./Certificate.scss";
import Img1 from "../../../../assets/images/choose-us1.png";
import Img2 from "../../../../assets/images/choose-us2.png";
import Img3 from "../../../../assets/images/choose-us3.png";
import Img4 from "../../../../assets/images/choose-us4.png";
import Img5 from "../../../../assets/images/choose-us5.png";

export default function Certificate() {
  return (
    <div className="certificate-wrapper">
      <div class="services pd">
        <div class="container">
          <h3 class="title">Why Choose Us</h3>
          <div class="row text-center align-items-center justify-content-center">
            <div class="col-md-2 align-items-center d-flex flex-column">
              <div
                style={{
                  backgroundColor: "#000000",
                  //   padding: "20px",
                  borderRadius: "100%",
                  padding: "30px",
                  marginBottom: "20px",
                  height: "150px",
                  width: "150px",
                }}
              >
                <img
                  src={Img1}
                  style={{
                    height: "100%",
                    resizeMode: "contain",
                    width: "100%",
                  }}
                />
              </div>
              <div class="serv">
                <h5>FAST</h5>
                <p>Teams of 2 For a Quick Cleaning</p>
              </div>
            </div>
            <div class="col-md-2 align-items-center d-flex flex-column">
              <div class="serv">
                <div
                  style={{
                    backgroundColor: "#000000",
                    //   padding: "20px",
                    borderRadius: "100%",
                    padding: "30px",
                    marginBottom: "20px",
                    height: "150px",
                    width: "150px",
                  }}
                >
                  <img
                    src={Img2}
                    style={{
                      height: "100%",
                      resizeMode: "contain",
                      width: "100%",
                    }}
                  />
                </div>
                <h5>INSURED</h5>
                <p>Fully Bonded and Insured</p>
              </div>
            </div>
            <div class="col-md-2 align-items-center d-flex flex-column">
              <div
                style={{
                  backgroundColor: "#000000",
                  //   padding: "20px",
                  borderRadius: "100%",
                  padding: "30px",
                  marginBottom: "20px",
                  height: "150px",
                  width: "150px",
                }}
              >
                <img
                  src={Img3}
                  style={{
                    height: "100%",
                    resizeMode: "contain",
                    width: "100%",
                  }}
                />
              </div>
              <div class="serv">
                <h5>SUPPLIES</h5>
                <p>We Bring Supplies</p>
              </div>
            </div>
            <div class="col-md-2 align-items-center d-flex flex-column">
              <div
                style={{
                  backgroundColor: "#000000",
                  //   padding: "20px",
                  borderRadius: "100%",
                  padding: "30px",
                  marginBottom: "20px",
                  height: "150px",
                  width: "150px",
                }}
              >
                <img
                  src={Img4}
                  style={{
                    height: "100%",
                    resizeMode: "contain",
                    width: "100%",
                  }}
                />
              </div>
              <div class="serv">
                <h5>QUICK BOOKING</h5>
                <p>Quick and Easy Booking</p>
              </div>
            </div>
            {/* <div class="col-md-2 align-items-center d-flex flex-column">
              <div
                style={{
                  backgroundColor: "#000000",
                  //   padding: "20px",
                  borderRadius: "100%",
                  padding: "30px",
                  marginBottom: "20px",
                  height: "150px",
                  width: "150px",
                }}
              >
                <img
                  src={Img5}
                  style={{
                    height: "100%",
                    resizeMode: "contain",
                    width: "100%",
                  }}
                />
              </div>
              <div class="serv">
                <h5>BEST OR FREE</h5>
                <p>Done Right or We Will Clean Your Home for Free</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
