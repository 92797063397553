import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./About.scss";

export default function AboutHero() {
  return (
    <>
      <div className="about-hero-wrapper">
        <div className="container">
          <h1>About Marvel Maids Cleaning</h1>
        </div>
      </div>
      <div className="about-section-1-wrapper">
        <section className="container mt-3 ">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-12">
              <div className="about-section">
                <h2>Who We Are</h2>
                <p className="text-start">
                  Our journey began with a simple desire to kill boredom and
                  earn some extra income. My mom started oraganizing and
                  cleaning spaces for additional funds, and surprisingly, I
                  joined her, proving that cleaning tasks were not beyond our
                  capabilities. Together, we transformed what seemed like
                  daunting chores into a seamless team effort.
                  <br></br>
                  <br></br>
                  As we navigated through houses, scrubbing toilets and tackling
                  grimy ovens, a realization dawned upon us — the immense
                  potential in the cleaning business. We recognized the growing
                  need for reliable, high-quality cleaning services. Many
                  households, offices, and commercial establishments were paying
                  for cleanliness but not receiving the quality they deserved.
                  <br></br>
                  <br></br>
                  Motivated by this insight, we decided to extend our cleaning
                  expertise to others. Marvel Maids was born out of a passion
                  for providing top-notch cleaning services that surpass
                  expectations. Our commitment is to deliver not just
                  cleanliness, but a sense of satisfaction and reliability. Join
                  us in maintaining spaces that truly shine.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              <img
                src="assets/images/about-section-1.jpg"
                className="img-fluid"
                alt="Company Image"
              />
            </div>
          </div>
        </section>
      </div>
      <div className="about-section-2-wrapper">
        <section className="container ">
          <div className="row">
            <div className="col-lg-4">
              <img
                src="assets/images/about-section-2.jpg"
                className="img-fluid"
                alt="Company Image"
              />
            </div>
            <div className="col-lg-8 col-md-8 col-12">
              <div className="about-section">
                <h2>We believe in higher standards for all.</h2>
                <p className="text-start">
                  When it comes to the health of your family, there should be no
                  compromise. Marvel Maids Cleaning Services was born out of a
                  parent’s journey to find a safe and effective alternative to
                  chemical-based cleaning products. You can trust our
                  eco-friendly products for your family and pets, because we
                  trust them for ours.Environmental health has been a part of
                  our mission since day one. We are constantly looking for ways
                  to do better for the planet and all those on it. All of
                  products are cruelty-free, 100% recyclable, and our line of
                  biodegradable, plastic-free products are a big step towards a
                  plastic-free future.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="about-section-3-wrapper">
        <div className="about-section-3">
          <h2 className="text-start">Rethink Clean</h2>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-12">
              <h3>POWERFUL & EFFECTIVE</h3>
              <p>
                Our products work hard and deliver the same results as
                conventional products.
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              <h3>NON-TOXIC & SAFE</h3>
              <p>
                Always chemical-free and made with organic, hypoallergenic, and
                plant-based ingredients.
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              <h3>PLANET FRIENDLY</h3>
              <p>
                Always chemical-free and made with organic, hypoallergenic, and
                plant-based ingredients.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="about-section-4-wrapper">
        <section className="container mt-3 ">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-12">
              <div className="about-section">
                <h2>House Cleaning Services</h2>
                <p className="text-start">
                  We bring the green clean to you. Our friendly, professional,
                  and eco-friendly house cleaning services in Vancouver,
                  Toronto, and Calgary, use only safe, non-toxic cleaning
                  products—and we offer a 100% customer satisfaction guarantee.
                  Available only in Canada.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <img
                src="assets/images/about-section-4.jpg"
                className="img-fluid"
                alt="Company Image"
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
