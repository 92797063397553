import React, { useState } from "react";
import "./Contact.scss";
import { toast } from "react-hot-toast";
import { validateField, removeValidation } from "../../../helpers";
import { contactService, verifyCaptchaService } from "../../../config/user";
import ReCAPTCHA from "react-google-recaptcha";

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [captchaValidated, setCaptchaValidated] = useState(false);

  async function onChange(value) {
    try {
      const res = await verifyCaptchaService({
        captcha: value,
      });
      if (res.data.status) {
        setCaptchaValidated(true);
      } else {
        setCaptchaValidated(false);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    removeValidation();
    let validate = true;
    if (!captchaValidated) {
      toast.error("Please validate captcha");
      return;
    }
    if (name.trim() === "") {
      validateField("name", "Name is required");
      validate = false;
    }

    if (email.trim() === "") {
      validateField("email", "Email is required");
      validate = false;
    }

    if (phone.trim() === "") {
      validateField("phone", "Phone is required");
      validate = false;
    }
    if (message.trim() === "") {
      validateField("message", "Message field is required");
      validate = false;
    }

    if (!validate) {
      toast("Please fill required fields");
      return;
    }
    try {
      setLoader(true);

      const res = await contactService({
        name,
        phone,
        email,
        message,
      });

      if (res.data.success) {
        toast("Thanks for Contacting Us!");
        removeValidation();
        setLoader(false);
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
      }
    } catch (err) {
      setLoader(false);
      console.log(err);
      if (err.response.data?.message) {
        toast.error(err.response.data.message);
      }
    }
  };
  return (
    <div className="contact-wrapper">
      {/* <section class="hero-section">
        <div class="container">
          <h1>Welcome to Our Cleaning Services</h1>
          <p>
            We provide professional and eco-friendly cleaning solutions for your
            home.
          </p>

        </div>
      </section> */}
      <div class="container text-center py-5">
        <div class="row">
          <div class="col-6 text-center mx-auto p-2">
            <div>
              <h2>Contact US</h2>
              <strong>General Inquires:</strong> marvelmaidsltd@gmail.com
              <br />
              {/* <strong> Media Inquires:</strong>news@marvelmaids.com
              <br /> */}
              <strong>Phone:</strong> 403-402-7738
            </div>
            <br />
            {/* <div>
              103-5023 Dollarton Highway
              <br />
              North Vancouver, BC V7H 0A2
            </div>
            <br />

            <div>
              <strong> Office Business Hours:</strong>
              <br />
              Monday-Friday: 7:00am - 4:30pm PST
              <br />
              Saturday: 7:00am - 3:00pm PST
              <br />
            </div> */}
          </div>
        </div>
        <div class="container mt-5">
          <div class="row">
            <div class="col-lg-6 mx-auto">
              <form method="post" onSubmit={onSubmit}>
                <div className="row">
                  <div className="col">
                    <div class="mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="name"
                        placeholder="Name*"
                        name="name"
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        value={name}
                        // autoComplete="new-password"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div class="mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="email"
                        placeholder="Email*"
                        name="email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        value={email}
                        // autoComplete="new-password"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div class="mb-3">
                    <input
                      type="text"
                      class="form-control"
                      id="phone"
                      placeholder="Phone number*"
                      name="phone"
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                      pattern="\d*"
                      value={phone}
                      autoComplete="new-password"
                    />
                  </div>
                </div>
                <div className="row">
                  <div class="mb-3">
                    <textarea
                      class="form-control"
                      id="message"
                      rows="5"
                      placeholder="Query*"
                      name="message"
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      value={message}
                      // autoComplete="new-password"
                    ></textarea>
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3">
                    <ReCAPTCHA
                      sitekey="6Led9qYmAAAAAIFCYjtS7CWvqK21Kg_iMNqhLU5j"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div>
                  {" "}
                  <button type="submit" class="btn btn-primary btn-contact">
                    Submit
                  </button>
                </div>
                <br />
              </form>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <h4 className="text-start">
            <strong>Vancouver House Cleaning Services</strong>
          </h4>
          <div className="col-lg-4 col-sm-6 text-start gap-0">
            Address
            <br />
            Marvel Maids
            <br />
            422 Richards St.
            <br />
            Vancouver, BC V6B 2Z4,
            <br />
            Canada
          </div>
          <div className="col-lg-4 col-sm-6 text-start gap-0">
            <strong>Phone</strong>
            <p>(604) 282-5055</p>
            <p>Email</p>
            <p>vancouver@marvelmaids.com</p>
          </div>
          <div className="col-lg-4 col-sm-6 text-start">
            <strong>Office Business Hours</strong>
            <p>Monday-Friday: 7:00am - 4:30pm PST</p>
            <p>Saturday: 7:00am - 3:00pm PST</p>
          </div>
        </div>
        <div className="row">
          <h4 className="text-start">
            <strong>Vancouver House Cleaning Services</strong>
          </h4>
          <div className="col-lg-4 col-sm-6 text-start gap-0">
            Address
            <br />
            Marvel Maids
            <br />
            544 Richards St.
            <br />
            Vancouver, BC V6B 2Z4,
            <br />
            Canada
          </div>
          <div className="col-lg-4 col-sm-6 text-start gap-0">
            <strong>Phone</strong>
            <p>(604) 283-4120</p>
            <p>Email</p>
            <p>vancouver@marvelmaids.com</p>
          </div>
          <div className="col-lg-4 col-sm-6 text-start">
            <strong>Office Business Hours</strong>
            <p>Monday-Friday: 7:00am - 4:30pm PST</p>
            <p>Saturday: 7:00am - 3:00pm PST</p>
          </div>
        </div>
        <div className="row">
          <h4 className="text-start">
            <strong>Vancouver House Cleaning Services</strong>
          </h4>
          <div className="col-lg-4 col-sm-6 text-start gap-0">
            Address
            <br />
            AGProCleaing
            <br />
            445 Richards St.
            <br />
            Vancouver, BC V6B 2Z4,
            <br />
            Canada
          </div>
          <div className="col-lg-4 col-sm-6 text-start gap-0">
            <strong>Phone</strong>
            <p>(604) 281-4100</p>
            <p>Email</p>
            <p>vancouver@marvelmaids.com</p>
          </div>
          <div className="col-lg-4 col-sm-6 text-start">
            <strong>Office Business Hours</strong>
            <p>Monday-Friday: 7:00am - 4:30pm PST</p>
            <p>Saturday: 7:00am - 3:00pm PST</p>
          </div>
        </div> */}
      </div>
    </div>
  );
}
