import {
  SET_CURRENT_USER,
  REMOVE_CURRENT_USER,
  SET_PROFILE,
  SET_NEW_PASSWORD,
  SET_TOKEN_EXPIRED,
  UPDATE_PROFILE_PHOTO,
  SET_ALL_USERS,
  SET_BLOGS,
  SET_SERVICES,
} from "../actions/types";

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  profile: "",
  newPassword: "",
  tokenExpired: false,
  allUsers: null,
  totalUsers: null,
  services: [],
  blogs: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        user: action.payload,
      };
    case REMOVE_CURRENT_USER:
      return {
        ...state,
        user: null,
        token: null,
        isAuthenticated: false,
        tokenExpired: false,
      };
    case SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case SET_BLOGS:
      return {
        ...state,
        blogs: action.payload,
      };
    case SET_SERVICES:
      return {
        ...state,
        services: action.payload,
      };
    case UPDATE_PROFILE_PHOTO:
      return {
        ...state,
        profile: {
          ...state.profile,
          photoUrl: action.payload,
        },
      };

    case SET_NEW_PASSWORD:
      return {
        ...state,
        newPassword: action.payload,
      };
    case SET_TOKEN_EXPIRED:
      return {
        ...state,
        tokenExpired: action.payload,
      };
    case SET_ALL_USERS:
      let { users, total } = action.payload;
      return {
        ...state,
        allUsers: users,
        totalUsers: total ? total : 0,
      };
    default:
      return {
        ...state,
      };
  }
}
