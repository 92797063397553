import React from "react";
import { Link, useNavigate } from "react-router-dom";

import Layout from "../../layout";

import "./index.scss";
import { useMediaQuery } from "react-responsive";

const DashBoard = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Layout>
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card card-main">
          <div class="card-body">
            <div class="row admin-card">
              <div class="col-md-4">
                <Link
                  class="datcard my-3"
                  to="/bookings"
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   navigate("/bookings");
                  // }}
                >
                  <span style={{ color: "black" }} class="h4">
                    Bookings
                  </span>
                  <p>Click here to go to the manage bookings.</p>
                  <div class="go-corner"></div>
                </Link>
              </div>

              <div class="col-md-4">
                <Link
                  className="datcard my-3"
                  to="/blogs-list/admin"
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   navigate("/");
                  // }}
                >
                  <span style={{ color: "black" }} class="h4">
                    Blogs
                  </span>
                  <p>Click here to go to the manage Blog Content.</p>
                  <div class="go-corner"></div>
                </Link>
              </div>
              <div class="col-md-4">
                <Link
                  className="datcard my-3"
                  to="/service-list/admin"
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   navigate("/service-list/admin");
                  // }}
                >
                  <span style={{ color: "black" }} class="h4">
                    Services
                  </span>
                  <p>Click here to go to the manage Services.</p>
                  <div class="go-corner"></div>
                </Link>
              </div>
              <div class="col-md-4">
                <Link
                  className="datcard my-3"
                  to="/contact-list/admin"
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   navigate("/service-list/admin");
                  // }}
                >
                  <span style={{ color: "black" }} class="h4">
                    Contact queries
                  </span>
                  <p>Click here to go to the manage queries.</p>
                  <div class="go-corner"></div>
                </Link>
              </div>
              <div class="col-md-4">
                <Link
                  className="datcard my-3"
                  to="/admin/admins-list"
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   navigate("/service-list/admin");
                  // }}
                >
                  <span style={{ color: "black" }} class="h4">
                    Users
                  </span>
                  <p>Click here to see list of users.</p>
                  <div class="go-corner"></div>
                </Link>
              </div>
              {isMobile && (
                <div class="col-md-4">
                  <Link
                    className="datcard my-3"
                    to="/admin/add-admin"
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   navigate("/service-list/admin");
                    // }}
                  >
                    <span style={{ color: "black" }} class="h4">
                      Add admin
                    </span>
                    <p>Click here to add admin</p>
                    <div class="go-corner"></div>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DashBoard;
