import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getBlogByIdService } from "../../../config/blog";
import { assetsURL } from "../../../config/axiosConfig";
import moment from "moment";

export default function BlogDetailSection() {
  const params = useParams();
  const [blog, setBlog] = useState({});
  const getBlog = async () => {
    try {
      const res = await getBlogByIdService(params.slug);
      if (res.data.blog) {
        setBlog(res.data.blog);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getBlog();
  }, []);
  return (
    <div className="container blog-detail-wrapper">
      <div className="w-100 d-flex justify-content-center">
        <img className="image" src={assetsURL + blog.image} />
      </div>
      <h3>{blog.title}</h3>
      <div className="detail-date">
        {moment(blog.created).format("MMMM DD,YYYY")}
      </div>
      <p
        dangerouslySetInnerHTML={{ __html: blog.desc }}
        className="paragraphs"
      ></p>
    </div>
  );
}
