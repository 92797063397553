import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, Toast } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  LoginService,
  guestLoginServoce,
  resendGuestOtpService,
  verifyGuestLoginService,
} from "../../config/user";
import { validateField } from "../../helpers";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import "./login.scss";
import { setCurrentUser } from "../../redux/actions/auth";

const Login = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState("");
  const [hash, setHash] = useState("");
  const [otp, setOtp] = useState("");
  const [show, setShow] = useState(false);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  const navigate = useNavigate();

  useEffect(() => {
    if (show) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }

        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [seconds, show]);

  const onSubmit = async (e) => {
    e.preventDefault();
    let validate = true;

    if (email.trim() === "") {
      validateField("email", "Email field is required");
      validate = false;
    }

    // if (password.trim() === "") {
    //   validateField("password", "Password field is required");
    //   validate = false;
    // }

    // if (password.trim().length < 8) {
    //   validateField("password", "Password should be minimum 8 characters");
    //   validate = false;
    // }

    if (!validate) {
      toast.error("Please fill required fields.");

      return;
    }
    try {
      setLoader(true);
      // const res = await LoginService({
      //   email,
      //   password,
      // });
      const res = await guestLoginServoce({ email });
      if (res.data.sent) {
        // sessionStorage.setItem("token", res.data.token);
        setShow(true);
        setHash(res.data.hash);
        toast.success("OTP sent on email please check");
        setLoader(false);
        // dispatch(setCurrentUser());
        // window.location.href = "/";
        // navigate("/");
      }
    } catch (err) {
      setLoader(false);
      if (err.response.data.message) {
        toast.error(err.response.data.message);
        // toast.error(err.response.data.message);
      }
      if (err.response.data.errors[0].msg) {
        toast.error(err.response.data.errors[0].msg);
      }
    }
  };
  const onVerify = async (e) => {
    e.preventDefault();
    let validate = true;

    if (otp.trim() === "") {
      validateField("otp", "OTP is required");
      validate = false;
    }
    if (!validate) {
      toast.error("Please fill required fields.");
      return;
    }
    try {
      setLoader(true);
      // const res = await LoginService({
      //   email,
      //   password,
      // });
      const res = await verifyGuestLoginService({ email, otp, hash });
      if (res.data.token) {
        sessionStorage.setItem("token", res.data.token);
        toast.success("OTP verified successfully");
        setLoader(false);
        dispatch(setCurrentUser());
        // window.location.href = "/";
        navigate("/");
      } else {
        setLoader(false);
        toast.error("Invalid OTP");
      }
    } catch (err) {
      setLoader(false);
      if (err.response.data.message) {
        toast.error(err.response.data.message);
        // toast.error(err.response.data.message);
      }
    }
  };

  const onResend = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      const res = await resendGuestOtpService({ email, hash });
      if (res.data.success) {
        setHash(res.data.hash);
        setMinutes(1);
        setLoader(false);
        setSeconds(30);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message);
        setLoader(false);
      }
      console.log(err);
    }
  };
  return (
    <div className="login-styles">
      <Row>
        <Col className="mt-4" md={12} xs={12}>
          <h2>{show ? "Verify OTP" : "Login"}</h2>
          <Form className="login-form" onSubmit={show ? onVerify : onSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              {show ? (
                <Form.Control
                  type="text"
                  placeholder="OTP"
                  name="otp"
                  value={otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                />
              ) : (
                <Form.Control
                  type="text"
                  placeholder="Email"
                  value={email}
                  name="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              )}
            </Form.Group>
            {show && (
              <a
                onClick={(e) =>
                  minutes === 0 && seconds === 0 ? onResend(e) : () => { }
                }
                href="#"
              >
                {minutes > 0 || seconds > 0 ? (
                  <>
                    Resend OTP{" "}
                    {minutes < 10 ? ` in  0${minutes}:` : minutes + ":"}
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </>
                ) : (
                  <>{"Resend OTP"}</>
                )}
              </a>
            )}
            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="password"
                placeholder="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                name="password"
              />
            </Form.Group> */}
            {show ? (
              <Button
                onClick={onVerify}
                type="submit"
                className="btn-login"
                variant="primary"
              >
                {loader ? "Please wait.." : "Verify"}
              </Button>
            ) : (
              <>
                <Button className="btn-login" variant="primary" type="submit">
                  {loader ? "Please wait.." : "Login"}
                </Button>

                <Link to="/register">Don't have an account? Register</Link>
              </>
            )}
          </Form>
        </Col>
      </Row>
    </div>
  );
};
export default Login;
