import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./single-service.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { assetsURL } from "../../../config/axiosConfig";

export default function ServiceOffered() {
  const { services } = useSelector((state) => state.auth);
  return (
    <Container className="single-service-offered">
      <Row style={{ paddingBottom: "75px" }}>
        {services.length > 0 &&
          services.map((service, key) => (
            <Col md={3} xs={12}>
              <Link
                className="text-decoration-none text-black"
                to={`/service/${service.slug}`}
              >
                <div className="col-div-single-service">
                  <Image
                    src={assetsURL + service.image}
                    className="service1-image"
                    height={100}
                    width={100}
                  />
                  <span className="mt-2">{service.title}</span>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: service.desc.substring(0, 100),
                    }}
                    className="text-center"
                  ></p>
                </div>
              </Link>
              {/* <div className="col-link">
                <Link to="#">{service.cleaningType} Services </Link>
                <Image
                  src="../assets/images/arrow-icon.svg"
                  height={20}
                  width={20}
                />
              </div> */}
            </Col>
          ))}
      </Row>
    </Container>
  );
}
