import React, { useEffect, useState } from "react";
import Layout from "../../../layout";
import {
  getBookingsService,
  updateBookingStatusService,
} from "../../../config/booking";
import moment from "moment";
import { useToaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { getContactQueiresService } from "../../../config/contact";
import { assetsURL } from "../../../config/axiosConfig";

const ContactsAdmin = () => {
  const [queries, setQueries] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [total, setTotal] = useState(0);
  const toast = useToaster();
  const [page, setPage] = useState(1);
  const getContacts = async () => {
    try {
      const res = await getContactQueiresService(`?page=${page}`);
      if (res.data) {
        setQueries(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getContacts();
  }, [page]);

  const renderContacts = (item, key) => {
    return (
      <tr>
        <td class="text-capitalize">{key + 1}</td>
        <td class="text-capitalize">
          {moment(item.created).format("DD/MM/YY")}
        </td>
        <td class="text-capitalize">{item.name}</td>
        <td class="text-capitalize">{item.email}</td>
        <td class="text-capitalize">{item.phone}</td>
        <td class="text-capitalize">{item.message}</td>
      </tr>
    );
  };
  return (
    <Layout header="Bookings">
      <div className=" mt-3">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <h4 className="card-title text-start">
                    Contact Queries List
                  </h4>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Created on</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {queries &&
                      queries.length > 0 &&
                      queries.map((i, key) => {
                        return renderContacts(i, key);
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactsAdmin;
